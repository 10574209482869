import * as React from "react";
import {useContext} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {Button, DialogActions} from "@mui/material";
import "./Actions.scss"
import GameContext from "../../../context/GameContext";

export default function InformationDialog() {

    const gameContext = useContext(GameContext)
    const handleClose = () => {
        gameContext.setOpen(false);
    };
    return (
        <Dialog onClose={handleClose} open={gameContext.isOpen} className={"cardActions"} style={{overflowY: "scroll"}}>
            <DialogTitle>
                <b>Hi! Here you can find the instructions!</b>
            </DialogTitle>
            <Typography
                sx={{m: 1,}}
                variant="h6"
                component="div"
            >
                <b>Gameplay</b>
            </Typography>
            <Typography sx={{m: 1,}} variant="body2">
                Players take turns, starting with the first player, who first placed their Workers. On your turn,
                select one of your Workers. You must move and then build with the selected Worker.
            </Typography>
            <Typography
                sx={{m: 1,}}
                variant="h6"
                component="div"
            >
                <b>Moving</b>
            </Typography>
            <Typography sx={{m: 1,}} variant="body2">
                Move your selected Worker into one of the (up to) eight neighboring spaces. A Worker may move up a
                maximum of one level higher, move down any number of levels lower, or move along the same level. A
                Worker may not move up more than one level. The space your Worker moves into must be unoccupied (not
                containing a Worker or Dome).
            </Typography>

            <Typography
                sx={{m: 1,}}
                variant="h6"
                component="div"
            >
                <b>Building</b>
            </Typography>
            <Typography sx={{m: 1}} variant="body2">
                Build a block or dome on an unoccupied space neighboring the moved Worker. You can build onto a
                level of any height, but you must choose the correct shape of block or dome for the level being
                built.
                A tower with 3 blocks and a dome is considered a "Complete Tower".
            </Typography>
            <Typography
                sx={{m: 1}}
                variant="h6"
                component="div"
            >
                <b>End of the game</b>
            </Typography>
            <Typography sx={{m: 1}} variant="body2">
                If one of your Workers moves up on top of level 3 during your turn, you instantly win!

                You must always perform a move then build on your turn. If you are unable to, you lose.

                You are now ready to play the base 2-player game! We strongly suggest you play several games using
                only these rules. Read on when you are ready for more!
            </Typography>

            <Typography
                sx={{m: 1}}
                variant="h6"
                component="div"
            >
                <b>Gods</b>
            </Typography>
            <Typography sx={{m: 1}} variant="body2">
                To make the game a bit spicier, every player has one god that is assigned to him. At the right you
                can find more information about every players god. They can change the whole strategy you need to
                apply in the game.<br/><br/>

                These are the available gods:<br/><br/>
                - <b>Apollo:</b> Your Move: Your Worker may move into an opponent Worker's space by forcing their
                Worker to the space yours just vacated.<br/><br/>
                - <b>Artemis:</b> Your Move: Your Worker may move one additional time, but not back to its initial
                space.<br/><br/>
                - <b>Demeter:</b> Your Build: Your Worker may build one additional time, but not on the same
                space.<br/><br/>
                - <b>Hephaestus:</b> Your Move: Your Worker may build one additional block (not dome) on top of
                your first block.<br/><br/>
                - <b>Pan</b>: Win Condition: You also win if your Worker moves down two or more levels.<br/><br/>
            </Typography>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
