import {useNavigate} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import React from "react";

export default function ErrorPage({code, message}:{code:number|string, message: React.ReactNode}) {
    const navigate = useNavigate()

    return <>
        <div className={"flexElements centerElements"}>
            <div>
                <Typography variant={"h2"} sx={{my: 10}}>
                    Error {code}: {message}
                </Typography>
                <div className={"flexElements centerElements"}>
                    <Button onClick={() => navigate("/")} startIcon={<WestIcon/>}>Home</Button>
                </div>
            </div>
        </div>
    </>
}
