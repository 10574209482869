import {useGetCollection} from "./useGetObject";
import GameInvite from "../types/GameInvite";
import {useState} from "react";
import useInterval from "./useInterval";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

export default function useGameInvites() {
    const {data, error, refresh} = useGetCollection<GameInvite>("/game/invites/get")
    const [inviteCount, setInviteCount] = useState(0)
    useInterval(refreshState, error ? 60 : 2)
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()

    async function refreshState() {
        await refresh()
        if(data.length > inviteCount) enqueueSnackbar("You have received a game invite!", {variant: "info", onClick: () => navigate("invites")})
        setInviteCount(data.length)
    }

    return {inviteCount}
}