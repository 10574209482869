import {useEffect, useState, useState as useStateNormal} from "react";
import FieldInfo from "../types/FieldInfo";
import useStateRef from "react-usestateref";
import {getObject} from "../functions/objectService";

export default function useCurrentGameStatus<T>(url: string, defaultValue: T) {
    const [loading, setIsLoading] = useStateNormal(true);
    const [errorMsg, setErrorMsg] = useState("")
    const [fetchedData, setFetchedData, fetchedDataRef] = useStateRef<T>(defaultValue);

    async function refetch() {
        try {
            const {data} = await getObject<T>(url)
            setFetchedData(data);
            setErrorMsg("");
        } catch (error) {
            setErrorMsg(error+"");
        }
    }

    function updateFields(fields: FieldInfo[]) {
        setFetchedData({...fetchedData, fields: fields})
    }

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            try {
                const {data} = await getObject<T>(url)
                setFetchedData(data);
                setErrorMsg("");
            } catch (error) {
                setErrorMsg(error+"");
            }
            setIsLoading(false);
        }

        getData();
    }, [url, setFetchedData, errorMsg, setIsLoading]);

    return {
        loading,
        error: errorMsg.length > 0,
        errorMsg,
        data: fetchedData,
        updateFields,
        fetchedDataRef,
        refetch,
    };
}
