import AllFriendsPage from "./AllFriendsPage";
import FriendRequestsPage from "./FriendRequestsPage";
import AddFriendsPage from "./AddFriendsPage";
import React from "react";
import {useTranslation} from "react-i18next";

interface ConditionalProps {
    item: string | null
}

export default function Conditional({item}: ConditionalProps) {
    const {t} = useTranslation()
    if (item === t("friends.friends")) {
        return <AllFriendsPage/>
    } else if (item === t("friends.requests")) {
        return <FriendRequestsPage/>
    } else {
        return <AddFriendsPage/>
    }
}
