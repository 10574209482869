import FieldInfo from "../types/FieldInfo";
import ActionInfo from "../types/ActionInfo";
import TurnInfo from "../types/TurnInfo";
import Circle, {CircleWithoutBuilder} from "../types/Circle";
import {findFieldByXAndY, getZByTowerSize, playerWentUp} from "./GameService";
import {GodAbility} from "../types/GodAbility";
import {getLastActionOfType} from "./ActionService";
import {ActionType} from "../types/ActionType";
import GameInfo from "../types/GameInfo";
import {getLastTurnsFromOtherPlayers} from "./TurnService";

export function checkIfMoveCircleIsPossible(game: GameInfo, field: FieldInfo, x: number, y: number, circleId: number, currentAction: ActionInfo, currentTurn: TurnInfo, turns: TurnInfo[]): Circle | undefined {
    let fieldCurrent = findFieldByXAndY(game.fields, x, y)
    if (fieldCurrent === undefined) return undefined
    if (!(fieldCurrent.towerSize - field?.towerSize <= 1)) return undefined
    if (fieldCurrent?.towerSize === 4) return undefined
    if (fieldCurrent?.builder !== null) {
        if (currentAction.godAbility === GodAbility.MOVE_INTO) {
            if(fieldCurrent.builder.player.id !== game.playerId) {
                return createCircle(circleId, field, fieldCurrent, x, y)
            }
            return undefined

        }
        return undefined
    }
    if (fieldCurrent.towerSize - field.towerSize === 1) {
        if (!checkIfCanGoUp(turns)) {
            return undefined
        }
    }
    if (currentAction.godAbility === GodAbility.EXTRA_MOVE_NOT_BACK) {
        let lastActionOfMoveType = getLastActionOfType(currentTurn.actions, ActionType.MOVE)
        if (lastActionOfMoveType?.fromField.x === x && lastActionOfMoveType?.fromField.y === y) {
            return undefined
        }
    }
    return createCircle(circleId, field, fieldCurrent, x, y)
}

export function checkIfBuildCircleIsPossible(game: GameInfo, x: number, y: number, circleId: number, field: FieldInfo, currentTurn: TurnInfo, currentAction: ActionInfo): Circle | undefined {
    let fieldCurrent = findFieldByXAndY(game.fields, x, y)
    if (fieldCurrent === undefined) return undefined
    if (fieldCurrent?.builder !== null) return undefined
    if (fieldCurrent?.towerSize === 4) return undefined
    if (currentAction?.godAbility !== null && currentAction?.godAbility !== undefined) {
        switch (currentAction.godAbility) {
            case GodAbility.EXTRA_BUILD_NOT_SAME:
                let lastActionOfBuildType = getLastActionOfType(currentTurn.actions, ActionType.BUILD)
                if (lastActionOfBuildType?.buildField.x === x && lastActionOfBuildType.buildField.y === y) {
                    return undefined
                } else {
                    return createCircle(circleId, field, fieldCurrent, x, y)
                }
            case GodAbility.EXTRA_BUILD_ONLY_SAME:
                let lastActionOfBuildTypeOnly = getLastActionOfType(currentTurn.actions, ActionType.BUILD)
                if (lastActionOfBuildTypeOnly?.buildField.x === x && lastActionOfBuildTypeOnly?.buildField.y === y) {
                    return createCircle(circleId, field, fieldCurrent, x, y)
                } else {
                    return undefined
                }
            default:
                return createCircle(circleId, field, fieldCurrent, x, y)
        }
    }
    return createCircle(circleId, field, fieldCurrent, x, y)

}

export function createCircle(circleId: number, field: FieldInfo, fieldCurrent: FieldInfo, x: number, y: number): Circle {
    let z: number = getZByTowerSize(fieldCurrent.towerSize)
    return {
        id: circleId,
        builderId: field?.builder?.id ?? null,
        x: x,
        y: y,
        z: z
    }
}

export function getCircles(game: GameInfo): CircleWithoutBuilder[] {
    let addBuilderCircles: CircleWithoutBuilder[] = []
    let count = 0
    for (let i = -2; i <= 2; i++) {
        for (let j = -2; j <= 2; j++) {
            let fieldCurrent = findFieldByXAndY(game.fields, i, j)
            if (fieldCurrent !== undefined && fieldCurrent.builder === null) {
                addBuilderCircles.push({
                    id: count,
                    x: i,
                    y: j,
                    z: 0,
                })
            }
            count++
        }
    }
    return addBuilderCircles
}

function checkIfCanGoUp(turns: TurnInfo[]): boolean {
    let lastTurns = getLastTurnsFromOtherPlayers(turns)
    let playerCanGoUp = true
    lastTurns.forEach(turn => {
        if ((turn.player.god?.ability ?? GodAbility.NONE) === GodAbility.OPPONENT_NOT_UP) {
            let moveAction = getLastActionOfType(turn.actions, ActionType.MOVE)
            if (moveAction !== undefined) {
                if (playerWentUp(moveAction.fromField, moveAction.toField)) {
                    playerCanGoUp = false
                }
            }
        }
    })
    return playerCanGoUp
}

export function getOptions(field: FieldInfo, game: GameInfo, actionType: ActionType, currentAction: ActionInfo, currentTurn: TurnInfo, turns: TurnInfo[]): Circle[] {
    let circles: Circle[] = []
    let count = 0
    for (let i = field.x - 1; i <= field.x + 1; i++) {
        for (let j = field.y - 1; j <= field.y + 1; j++) {
            if (i === field.x && j === field.y) {
            } else {
                if (actionType === ActionType.MOVE) {
                    let circle = checkIfMoveCircleIsPossible(game, field, i, j, count, currentAction, currentTurn, turns)
                    if (circle !== undefined) {
                        circles.push(circle)
                    }
                }
                if (actionType === ActionType.BUILD) {
                    let circle = checkIfBuildCircleIsPossible(game, i, j, count, field, currentTurn, currentAction)
                    if (circle !== undefined) {
                        circles.push(circle)
                    }
                }
                count++
            }
        }
    }
    return circles
}
