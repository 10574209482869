import React, {ReactNode, useEffect, useState} from "react";
import GameInfo, {defaultGameInfo} from "../types/GameInfo";
import GameContext from "./GameContext";
import {useNavigate, useParams} from "react-router-dom";
import Circle, {CircleWithoutBuilder} from "../types/Circle";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import useCurrentGameStatus from "../hooks/useCurrentGameStatus";
import useTurnsFromGame from "../hooks/useTurnsFromGame";
import useInterval from "../hooks/useInterval";
import Error404 from "../pages/errors/Error404";
import BuilderInfo from "../types/BuilderInfo";
import TurnInfo from "../types/TurnInfo";
import ActionInfo from "../types/ActionInfo";
import {ActionType} from "../types/ActionType";
import FieldInfo from "../types/FieldInfo";
import {GodAbility} from "../types/GodAbility";
import {ResponseType} from "../types/ResponseType";
import {
    findBuilderById,
    findFieldByBuilderId,
    getLastMoveAction,
    hasGameBeenWon,
    hasGameStarted,
    isAllowedGodAbility,
    validatePawnClick
} from "../services/GameService";
import {
    getActionAtIndex,
    getLastActionOfType,
    handleAddBuilderAction,
    handleBuildAction,
    handleMoveAction,
    handlePassGodAction,
    handleResetAction
} from "../services/ActionService";
import {getTurnAtIndex} from "../services/TurnService";
import {getCircles, getOptions} from "../services/CircleService";
import {useGameLobby} from "../hooks/useGameLobby";


interface GameContextProviderProps {
    children: ReactNode
}

function useBestRefreshDelay(game: GameInfo | null) {
    if (game === null) return 3
    if (game.startedOn === null) return 4
    return 1
}

export default function GameContextProvider({children}: GameContextProviderProps) {
    const {tokenId} = useParams<{ tokenId: string }>()
    const [builderId, setBuilderId] = useState<number | undefined>(undefined)
    const [moveCircles, setMoveCircles] = useState<Circle[]>([])
    const [resetButtonActive, setResetButtonActive] = useState<boolean>(false)
    const [passGodActionActive, setPassGodActionActive] = useState<boolean>(false)
    const [buildCircles, setBuildCircles] = useState<Circle[]>([])
    const [isReset, setIsReset] = useState<boolean>(false)
    const [addBuilderCircles, setAddBuilderCircles] = useState<CircleWithoutBuilder[]>([])
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)


    const {
        loading,
        data: game,
        fetchedDataRef,
        errorMsg,
        refetch: refetchGameStatus,
    } = useCurrentGameStatus<GameInfo>("/game/" + tokenId, defaultGameInfo)

    const {
        loading: loadingTurns,
        data: turns,
        refetch: refetchTurns
    } = useTurnsFromGame({
        url: "/game/" + tokenId + "/turns",
        actionUrl: "/game/" + tokenId + "/add_action",
        updateTurn: updateTurn,
        reset: reset,
    })

    const {
        handleLeaveGame,
        handleKick,
        inviteUser,
        handleStartGame
    } = useGameLobby({tokenId, refetchGameStatus, refetchTurns})

    useEffect(() => {
        if (errorMsg.includes("401")) {
            navigate("/")
            enqueueSnackbar(t("lobby.youGotKicked"), {variant: "error"})
        }
    }, [errorMsg, enqueueSnackbar, t, navigate])

    useInterval(refetchTurns, useBestRefreshDelay(game))
    useInterval(refetchGameStatus, useBestRefreshDelay(game))

    if (game.id === 0 && !loading) return <Error404/>

    function resetCirclesAndButtons() {
        if (!isReset) {
            setIsReset(true)
            if (!everythingIsReset()) {
                setResetButtonActive(false)
                setPassGodActionActive(false)
                setBuilderId(undefined)
                setBuildCircles([])
                setMoveCircles([])
                setAddBuilderCircles([])
            }
        }
    }

    function reset() {
        setIsReset(false)
    }

    function changeOpenState(open: boolean) {
        setOpen(open)
    }

    function everythingIsReset() {
        if (buildCircles.length > 0) return false
        if (moveCircles.length > 0) return false
        if (addBuilderCircles.length > 0) return false
        if (resetButtonActive) return false
        if (passGodActionActive) return false
        return builderId === undefined;

    }

    async function handleResetButtonClick() {
        setResetButtonActive(false)
        setPassGodActionActive(false)
        setBuilderId(undefined)
        setBuildCircles([])
        setMoveCircles([])
        setAddBuilderCircles([])
        await handleResetAction(turns, tokenId)
    }

    async function handlePassGodActionClick() {
        resetCirclesAndButtons()
        await handlePassGodAction(turns, tokenId)
    }

    function handlePawnClick(builderIdFromParam: number | undefined) {
        if (!isReset && builderIdFromParam !== undefined) {
            let builder: BuilderInfo | undefined = findBuilderById(builderIdFromParam, game)
            if (builder !== undefined && builder.player.id === game.playerId) {
                let currentTurn = getTurnAtIndex(0, turns)
                let currentAction = getActionAtIndex(currentTurn, 0)
                if (validatePawnClick(game, currentAction, builderId, currentTurn)) {
                    setResetButtonActive(true)
                    setBuilderId(builderIdFromParam)
                    if (currentTurn !== undefined && currentAction !== undefined) {
                        showOptionsAroundPawn(findFieldByBuilderId(builderIdFromParam, game), ActionType.MOVE, currentTurn, currentAction)
                    }
                }
            }
        }
    }

    function showOptionsAroundPawn(field: FieldInfo | undefined, actionType: ActionType, currentTurn: TurnInfo, currentAction: ActionInfo) {
        if (field !== undefined) {
            if (actionType === ActionType.MOVE) {
                setMoveCircles(getOptions(field, fetchedDataRef.current, actionType, currentAction, currentTurn, turns))
            } else {
                setBuildCircles(getOptions(field, fetchedDataRef.current, actionType, currentAction, currentTurn, turns))
            }
        }
    }

    async function handleCircleClick(x: number, y: number) {
        setPassGodActionActive(false)
        setResetButtonActive(false)
        if (builderId !== undefined) {
            setMoveCircles([])
            await handleMoveAction(game, x, y, builderId, turns, tokenId)
        }
    }

    function showAddBuilderOptions() {
        setAddBuilderCircles(getCircles(fetchedDataRef.current))
    }

    function updateTurn(turn: TurnInfo, actionType: ResponseType, nextTurn: TurnInfo | null | undefined) {
        let currentAction = getActionAtIndex(turn, 0)
        let previousAction = getLastMoveAction(turn)
        let currentActionForGodAction = getActionAtIndex((nextTurn === null || nextTurn === undefined) ? turn : nextTurn, 0)
        if (currentActionForGodAction?.godAbility !== null && turn.player.id === fetchedDataRef.current.playerId) {
            if (currentActionForGodAction !== undefined) {
                if (isAllowedGodAbility(currentActionForGodAction.godAbility)) {
                    setPassGodActionActive(true)
                }
            }
        }
        switch (currentAction?.type) {
            case ActionType.MOVE:
                handleMove(currentAction, actionType, turn)
                break
            case ActionType.BUILD:
                handleBuild(previousAction, currentAction, turn, actionType)
                break
            case ActionType.ADD_BUILDER:
                handleAddBuilder(nextTurn, turn)
                break
            default:
                break
        }
    }

    function handleMove(currentAction: ActionInfo, responseType: ResponseType, turn: TurnInfo) {
        if (currentAction.godAbility === GodAbility.EXTRA_MOVE_NOT_BACK) {
            let actionOfMove = getLastActionOfType(turn.actions, ActionType.MOVE)
            if (responseType !== ResponseType.NEW_TURN && actionOfMove !== undefined && turn.player.id === fetchedDataRef.current.playerId) {
                setResetButtonActive(true)
                setBuilderId(actionOfMove?.toField.builder?.id)
                showOptionsAroundPawn(actionOfMove?.toField, ActionType.MOVE, turn, currentAction)
            }
        }
    }

    function handleBuild(previousAction: ActionInfo | undefined, currentAction: ActionInfo, turn: TurnInfo, responseType: ResponseType) {
        if (responseType !== ResponseType.NEW_TURN && previousAction !== undefined && turn.player.id === fetchedDataRef.current.playerId) {
            setBuilderId(undefined)
            setResetButtonActive(true)
            showOptionsAroundPawn(previousAction.toField, ActionType.BUILD, turn, currentAction)
        }
    }

    function handleAddBuilder(nextTurn: TurnInfo | null | undefined, turn: TurnInfo) {
        let playerId
        if (nextTurn === null) {
            playerId = turn.player.id
        } else {
            let currentActionNext = getActionAtIndex(nextTurn, 0)
            if (currentActionNext?.type === ActionType.ADD_BUILDER) {
                playerId = nextTurn?.player.id
            }
        }
        if (playerId === fetchedDataRef.current.playerId) {
            showAddBuilderOptions()
        }
    }

    async function handleAddBuilderCircleClick(x: number, y: number) {
        setAddBuilderCircles([])
        await handleAddBuilderAction(turns, game, x, y, tokenId)
    }

    async function handleBuildCircleClick(x: number, y: number) {
        setPassGodActionActive(false)
        setResetButtonActive(false)
        setBuildCircles([])
        await handleBuildAction(turns, game, x, y, tokenId)
    }

    return <GameContext.Provider value={{
        game: game,
        buildCircles: buildCircles,
        moveCircles: moveCircles,
        builderId: builderId,
        addBuilderCircles: addBuilderCircles,
        turns: turns,
        resetButtonActive: resetButtonActive,
        passGodActionButtonActive: passGodActionActive,
        loadingTurns: loadingTurns,
        loading: loading,
        tokenId: tokenId,
        isOpen: open,
        setOpen: changeOpenState,
        handleResetButtonClick: handleResetButtonClick,
        handlePassGodButtonClick: handlePassGodActionClick,
        handleBuildCircleClick: handleBuildCircleClick,
        handleCircleClick: handleCircleClick,
        handlePawnClick: handlePawnClick,
        handleLeaveGame: handleLeaveGame,
        handleStartGame: handleStartGame,
        hasGameStarted: hasGameStarted,
        hasGameBeenWon: hasGameBeenWon,
        inviteUser: inviteUser,
        resetCirclesAndButtons: resetCirclesAndButtons,
        handleAddBuilderCircleClick: handleAddBuilderCircleClick,
        handleKick: handleKick
    }}>
        {children}
    </GameContext.Provider>
}
