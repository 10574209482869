import {useGame} from "./GamePage";
import {Avatar, Button, Paper, Typography} from "@mui/material";
import Board from "../components/particles/game/Board";
import React, {useEffect} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import useGetSingle from "../hooks/useGetObject";
import {minutesBetween} from "../functions/date";
import {playerIsUser} from "../types/PlayerInfo";
import {BOT_NAME} from "../util/GameUtil";

export default function WinnerPage() {
    const gameContext = useGame()
    const {
        loading,
        error,
        errorMsg,
        data
    } = useGetSingle(`/game/summary/${gameContext.game.linkToken}`, {amountOfTurnsForPlayer: 0})
    const {enqueueSnackbar} = useSnackbar()
    const {t} = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg, {variant: "error"})
    }, [error, errorMsg, enqueueSnackbar])

    if (gameContext.game.winner == null) {
        enqueueSnackbar("Failed to fetch winner of game.", {variant: "error"})
        return <BoardWithSky/>
    }

    return <>
        <div style={{zIndex: +2, position: "absolute", width: "100vw", height: "100vh"}}
             className={"flexElements centerElements verticalAlignCenter"}>
            <Paper style={{padding: 17}}>
                <div className={"flexElements"}>
                    <div style={{marginRight: 5, padding: 5}}>
                        <Avatar sx={{width: 65, height: 65}}
                                src={gameContext.game.winner.user === null ? "/images/bot/bot.png" : gameContext.game.winner.user?.avatarUrl}/>
                    </div>
                    <div>
                        <Typography variant={"h5"}>{t("game.winnerPage.winner")}:</Typography>
                        <Typography
                            variant={"h4"}>{gameContext.game.winner.user === null ? BOT_NAME : gameContext.game.winner.user?.nickname}</Typography>
                    </div>
                </div>
                <div style={{padding: 10}}>
                    <Typography
                        variant={"subtitle1"}>{t("game.winnerPage.turnDuration")}: {gameContext.game.turnDurationInSeconds}s</Typography>
                    <Typography
                        variant={"subtitle1"}>{t("game.winnerPage.gameDuration")}: {minutesBetween(gameContext.game.startedOn, gameContext.game.endedOn)}{t("game.winnerPage.minutes")}</Typography>
                    <Typography
                        variant={"subtitle1"}>{t("game.winnerPage.totalMoves", {times: !loading && !error ? data.amountOfTurnsForPlayer : '?'})}</Typography>
                    <Typography variant={"subtitle1"}>{t("game.winnerPage.participants")}: <ul
                        style={{marginTop: 0}}>{gameContext.game.players.filter(playerIsUser).map(e => <li
                        key={e.id}>{e.user?.nickname ?? ""}</li>)}</ul></Typography>
                </div>
                <div className={"flexElements centerElements"}>
                    <Button startIcon={<WestIcon/>} onClick={() => navigate("/")} sx={{mt: 1}}
                            variant={"contained"}>{t("game.winnerPage.backToHome")}</Button>
                </div>
            </Paper>
        </div>
        <BoardWithSky/>
    </>
}

function BoardWithSky() {
    return <div className={"sky"}>
        <Board/>
    </div>
}
