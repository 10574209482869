import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0F4E9D',
        },
        secondary: {
            main: '#8c9058',
        },
        background: {
            default: '#6fd3fc',
            paper: '#debc8f',

        },
        text: {
            primary: '#22262a',
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'rgba(103, 209, 251, 0.30)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                    border: '1px solid rgba(103, 209, 251, 0.3)',
                }
            },
        },
    },

});

export default theme;
