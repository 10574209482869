import {Loading} from "../Loading";
import Error404 from "../../../pages/errors/Error404";
import WinnerPage from "../../../pages/WinnerPage";
import SantoriniGame from "./SantoriniGame";
import BeforeGame from "./BeforeGame";
import React, {useContext} from "react";
import GameContext from "../../../context/GameContext";

export default function GameContextBridge() {

    const {loading, loadingTurns, tokenId, game, hasGameStarted, hasGameBeenWon} = useContext(GameContext);

    if (loading || loadingTurns) return <Loading/>

    if (tokenId === undefined) return <Error404/>

    return (
        hasGameStarted(game) ? hasGameBeenWon(game) ? <WinnerPage/> : <SantoriniGame/> : <BeforeGame/>
    )
}
