import UserInfo from "./UserInfo";
import God from "./God";

export default interface PlayerInfo {
    id: number,
    color: string
    god: God
    user: UserInfo|null
}

export function playerIsBot(p:PlayerInfo) {
    return p.user === null
}

export function playerIsUser(p:PlayerInfo) {
    return !playerIsBot(p)
}
