import {useSnackbar} from "notistack";
import React from "react";
import {useNavigate} from "react-router-dom";
import {putObjectWithResponse} from "../functions/objectService";
import GameTitle from "../components/particles/game/GameTitle";
import {Button, TextField, Typography} from "@mui/material";
import useAuth from "../hooks/useAuth";
import {Box} from "@mui/system";
import Background from "../components/particles/Background";
import "./EditProfilePage.scss"
import ProfileIcon from "../components/particles/User/ProfileIcon";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

interface IEditProfile {
    firstname: string;
    lastname: string;
    nickname: string;
    email: string;
}

const schema = yup.object({
    firstname: yup.string().required().max(20).min(2),
    lastname: yup.string().required().max(20).min(2),
    nickname: yup.string().required().max(10).min(2),
    email: yup.string().required().min(2).matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,"Is not a valid email"),
});


export default function EditProfilePage() {
    const auth = useAuth();
    const notification = useSnackbar();
    const {t} = useTranslation();
    const {register, formState: {errors}, handleSubmit} = useForm<IEditProfile>({
        resolver: yupResolver(schema),
        defaultValues: {
            firstname: auth.user?.firstname,
            lastname: auth.user?.lastname,
            nickname: auth.user?.nick,
            email: auth.user?.email
        }
    });
    const onSubmit: SubmitHandler<IEditProfile> = data => SubmitData(data);
    const navigate = useNavigate()

    async function SubmitData(data: IEditProfile) {
        try {
            var response = await putObjectWithResponse<IEditProfile, string>("/auth/update", data)
            notification.enqueueSnackbar(`Account updated`, {variant: "success"})
            auth.setToken(response.data);
            navigate("/Profile")
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to update your account.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
            if ((`${e}`).includes("400")) notification.enqueueSnackbar("Email/username already exists.", {variant: "error"})
        }
    }

    return <Background>
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Box
                component="form"
                noValidate
                sx={{maxWidth: 800, marginBottom: 20}}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ProfileIcon/>
                <GameTitle/>
                <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("editprofile.editprofile")}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("editprofile.firstname")}
                    variant="filled"
                    {...register("firstname")}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.firstname?.message}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("editprofile.lastname")}
                    variant="filled"
                    {...register("lastname")}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.lastname?.message}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("editprofile.email")}
                    variant="filled"
                    {...register("email")}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.email?.message}</Typography>
                <TextField
                    fullWidth
                    className="textfieldLogin"
                    margin={"dense"}
                    label={t("editprofile.username")}
                    variant="filled"
                    {...register("nickname")}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.nickname?.message}</Typography>
                <Button sx={{m: 1}} type="submit" variant="contained">{t("editprofile.change")}</Button>
                <Button sx={{m: 1}} onClick={() => navigate("/profile/preferences")}
                        variant="contained">{t("editprofile.preferences")}</Button>
                <Button sx={{m: 1}} onClick={() => navigate("/profile/cardlayout")}
                        variant="contained">{t("editprofile.cardlayout")}</Button>
                <Button sx={{m: 1}} onClick={() => navigate("/profile/AwardTokens")}
                        variant="contained">{t("editprofile.awardtokens")}</Button>
                <Button sx={{m: 1}} onClick={() => auth.setToken("")}
                        variant="contained">{t("editprofile.logout")}</Button>
            </Box>
        </div>
    </Background>
}
