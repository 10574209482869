import ButtonUtil from "../friends/ButtonUtil";
import {CardActions, IconButton, Typography} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import Coins from "../../../media/img/Coin.png"

export default function ReturnRightContentForCard({buttonName,buttonFunction,buttonFunction2,userRelationShip,rewardTokens}:{buttonName:string,buttonFunction:() => Promise<void>|null,buttonFunction2:() => Promise<void>|null,userRelationShip:string,rewardTokens:number|undefined}) {

    switch (buttonName) {
        case "addFriend":
            return   <ButtonUtil buttonType={userRelationShip} sendFriendRequest={buttonFunction}/>;
        case "friendRequest":
            return   <CardActions >
                <IconButton onClick={buttonFunction} color="primary" aria-label="upload picture" component="label">
                    <CheckIcon/>
                </IconButton>
                <IconButton onClick={buttonFunction2} color="primary" aria-label="upload picture" component="label">
                    <DeleteIcon/>
                </IconButton>
            </CardActions>;
        case "profile":
           return <Typography variant={"h5"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} sx={{alignContent:"right"}}>
               {rewardTokens}
               <img src={Coins} style={{width:25,height:25}} alt={"Coins"} />
           </Typography>

        default:
           return <></>
    }
}
