import GameInvite, {InviteStatus} from "../../../types/GameInvite";
import LobbySection from "./LobbySection";
import {useSnackbar} from "notistack";
import {useEffect} from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import {Button} from "@mui/material";
import {useGetCollection} from "../../../hooks/useGetObject";
import useInterval from "../../../hooks/useInterval";

export default function InviteList({gameToken}: { gameToken: string }) {
    const {
        data,
        loading,
        error,
        errorMsg,
        hasLoaded,
        refresh: refreshInvites
    } = useGetCollection<GameInvite>(`/game/invites/get/${gameToken}`)
    const {enqueueSnackbar} = useSnackbar()
    useInterval(refreshInvites, 2)

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg)
    }, [error, errorMsg, enqueueSnackbar])

    if (loading && !hasLoaded) return <></>
    if (error) return <></>
    if (data.length === 0) return <></>

    return <LobbySection title={"Invites"}>
        {data.map(e => <InviteUserStatus key={e.id} gameInvite={e}/>)}
    </LobbySection>
}

function InviteUserStatus({gameInvite}: { gameInvite: GameInvite }) {
    return <div>
        <Button color={inviteColor(gameInvite.status)}
                endIcon={<InviteStatusIcon status={gameInvite.status}/>}>{gameInvite.receiver.nickname}</Button>
    </div>
}

function InviteStatusIcon({status}: { status: InviteStatus }) {
    switch (status) {
        case InviteStatus.Accepted:
            return <CheckIcon/>
        case InviteStatus.Pending:
            return <AutorenewIcon/>
        case InviteStatus.Rejected:
            return <ClearIcon/>
        default:
            return <NotInterestedIcon/>
    }
}

function inviteColor(status: InviteStatus) {
    switch (status) {
        case InviteStatus.Accepted:
            return "success"
        case InviteStatus.Pending:
            return "info"
        case InviteStatus.Rejected:
            return "error"
        default:
            return "warning"
    }
}
