import * as THREE from 'three'
import React from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import LevelProps from "../../../types/ThreeJsProps";
import {LEVEL_SCALE} from "../../../util/GameUtil";

type GLTFResult = GLTF & {
    nodes: {
        Lvl1_V3: THREE.Mesh
        Cube001: THREE.Mesh
    }
    materials: {
        ['plaster old house']: THREE.MeshStandardMaterial
        ['Material.001']: THREE.MeshStandardMaterial
    }
}

export default function Level1({position}: LevelProps) {
    const {nodes, materials} = useGLTF('/board/level1.glb') as unknown as GLTFResult
    return (
        <group scale={LEVEL_SCALE} position={position} dispose={null}>
            <mesh geometry={nodes.Lvl1_V3.geometry} material={materials['plaster old house']} position={[0, 12.95, 0]}
                  rotation={[Math.PI / 2, 0, 0]}>
            </mesh>
            <mesh geometry={nodes.Cube001.geometry} material={materials['Material.001']} position={[0, 11, 0]}
                  rotation={[Math.PI, Math.PI / 2, 0]}>
            </mesh>

        </group>
    )
}

useGLTF.preload('/board/level1.glb')
