import {IconButton, SwipeableDrawer, useMediaQuery} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import SideInfo from "./SideInfo";
import React, {useContext} from "react";
import GameContext from "../../../context/GameContext";
import ProfileIcon from "../User/ProfileIcon";

export default function ScalableSideInfo({isForGame}:{isForGame:Boolean}) {
    const gameContext = useContext(GameContext)

    const [isOpen, setIsOpen] = React.useState({
        right: false,
    });
    type Anchor = 'right';
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setIsOpen({...isOpen, [anchor]: open});
            };
    const child = ()=>{
        if(isForGame){
            return  <SideInfo
                currentTurn={gameContext.turns === undefined ? undefined : gameContext.turns.sort((a, b) => a.id - b.id).reverse().at(0)}/>
        }
        else{
            return  <ProfileIcon/>
        }
    }

    return (<>{useMediaQuery("(max-width: 1000px)") ? (
        <><IconButton
            style={{
                position: "absolute",
                right: 10,
                top: 25,
                zIndex: 2,
                cursor: 'pointer',
                padding: 0,
                height: "30px",
                width: "30px"
            }}
            onClick={toggleDrawer('right', true)} size="large">
            <InfoIcon/>
        </IconButton>
            <SwipeableDrawer
                anchor={'right'}
                open={isOpen['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}>
                <div style={{
                    width: 300, height: "100%",
                }}>
                    <IconButton
                        sx={{position: "absolute", right: 5, top: 5, padding: 1, zIndex: 5}}
                        onClick={
                            toggleDrawer('right', false)}><CloseIcon/></IconButton>
                    {child()}
                </div>
            </SwipeableDrawer>
        </>
    ) : (
        <div>
            {child()}
        </div>
    )}</>)
}