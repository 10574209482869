import {Button, Typography} from "@mui/material";
import "./StorePage.scss"
import React, {useState} from "react";
import BuyableItemsLibrary from "../components/particles/store/BuyableItemsLibrary";
import BoughtLibrary from "../components/particles/store/BoughtLibrary";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/system";
import ScalableSideInfo from "../components/particles/game/ScalableSideInfo";


let keyCounter = 0

export default function StorePage() {
    const [page, setPage] = useState("StorePage");
    const {t} = useTranslation();
    const buttons = [t("store.store"), t("store.library")];


    const buttonUtil = buttons.map(button => {
        if (button === page) {
            return <Button key={keyCounter++} style={{margin: 2, backgroundColor: "white", color: "#003D92"}}
                           variant={"contained"} onClick={() => setPage(button)}>{button}</Button>
        } else {
            return <Button key={keyCounter++} style={{margin: 2}} variant={"contained"}
                           onClick={() => setPage(button)}>{button}</Button>
        }
    });
    const children = () => {
        if (page === "StorePage") {
            return <BuyableItemsLibrary/>
        } else {
            return <BoughtLibrary/>
        }
    }

    return (
        <Box
            component="div"
            sx={{overflowY: "hidden"}}
        >
            <ScalableSideInfo isForGame={false}/>
            <Typography variant={"h1"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}
                        sx={{textAlign: "center"}}>Santorini</Typography>
            <Typography variant={"h2"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}
                        sx={{textAlign: "center"}}>{t("store.store")}</Typography>
            {buttonUtil}
            {children()}
        </Box>
    )
}
