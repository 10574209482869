import * as THREE from 'three'
import React from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import LevelProps from "../../../types/ThreeJsProps";
import {DOME_SCALE, DOME_Y} from "../../../util/GameUtil";

type GLTFResult = GLTF & {
    nodes: {
        Dome: THREE.Mesh
    }
    materials: {
        ['Material.001']: THREE.MeshStandardMaterial
    }
}


export default function Dome({position}: LevelProps) {
    const {nodes, materials} = useGLTF('/board/dome.glb') as unknown as GLTFResult
    return (
        <group scale={DOME_SCALE} position={position} dispose={null}>
            <mesh geometry={nodes.Dome.geometry} material={materials['Material.001']} position={[0, DOME_Y, 0]}
                  rotation={[Math.PI / 2, 0, 0]}/>
        </group>
    )
}

useGLTF.preload('/board/dome.glb')
