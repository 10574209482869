import "../../../pages/GamePage.scss"
import Board from "./Board";
import GameStatus from "./GameStatus";
import React from "react";
import Timer from "./Timer";
import {useGame} from "../../../pages/GamePage";
import ScalableSideInfo from "./ScalableSideInfo";
import InformationDialog from "./FirstTimeHintDialog";
import InformationButton from "./InformationButton";

export default function SantoriniGame() {
    const gameContext = useGame();

    return (
        <div className={"sky"}>
            <Board/>
            <ScalableSideInfo isForGame={true}/>
            <GameStatus
                currentTurn={gameContext.turns === undefined ? undefined : gameContext.turns.sort((a, b) => a.id - b.id).reverse().at(0)}/>
            <Timer/>
            <InformationDialog/>
            <InformationButton/>
        </div>


    )
}