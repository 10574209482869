import {Route, Routes} from "react-router-dom";
import RegistrationPage from "../../pages/RegistrationPage";
import LoginPage from "../../pages/LoginPage";
import React from "react";
import Error404 from "../../pages/errors/Error404";
import AnonymousHomePage from "../../pages/AnonymousHomePage";
import useAuth from "../../hooks/useAuth";
import AuthorizedHomePage from "../../pages/AuthorizedHomePage";
import EditProfilePage from "../../pages/EditProfilePage";
import GamePage from "../../pages/GamePage";
import Background from "../particles/Background";
import InvitesPage from "../../pages/InvitesPage";
import StorePage from "../../pages/StorePage";
import FriendsPage from "../../pages/FriendsPage";
import SendEmailForPasswordReset from "../../pages/SendMailForPasswordResetPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import PreferencesPage from "../../pages/PreferencesPage";
import EnableAccountPage from "../../pages/EnableAccountPage";
import AwardedAwardTokens from "../../pages/AwardedAwardTokens";
import EditCardPage from "../../pages/EditCardPage";
import useGetSingle from "../../hooks/useGetObject";
import ErrorPage from "../../pages/errors/ErrorPage";

export default function Router() {
    const {data : serverDate} = useGetSingle("/health/time", new Date())
    const auth = useAuth();

    if((new Date(serverDate)).getHours() !== (new Date()).getHours()) return <ErrorPage code={510} message={`The application is not made for your timezone. Expected ${serverDate.getHours()}, but found ${(new Date()).getHours()}.`} />

    return <Routes>
        {auth.isAuthorized() && <Route index element={<Background><AuthorizedHomePage/></Background>}/>}
        {!auth.isAuthorized() &&
            <Route path="/SendResetMail" element={<Background><SendEmailForPasswordReset/></Background>}/>}
        {!auth.isAuthorized() && <Route index element={<Background><AnonymousHomePage/></Background>}/>}
        {!auth.isAuthorized() && <Route path="/Register" element={<RegistrationPage/>}/>}
        {!auth.isAuthorized() && <Route path="/Login" element={<Background><LoginPage/></Background>}/>}
        {!auth.isAuthorized() && <Route path="/EnableUser/:token" element={<EnableAccountPage/>}/>}
        {!auth.isAuthorized() &&
            <Route path="/ResetPassword/:passwordToken" element={<Background><ResetPasswordPage/></Background>}/>}
        {auth.isAuthorized() && <Route path="/Game/:tokenId" element={<Background><GamePage/></Background>}/>}
        {auth.isAuthorized() && <Route path="/Invites" element={<Background><InvitesPage/></Background>}/>}
        {auth.isAuthorized() &&
            <Route path="/Invite/:inviteToken" element={<Background><AuthorizedHomePage/></Background>}/>}
        {auth.isAuthorized() && <Route path="/Profile/AwardTokens" element={<AwardedAwardTokens/>}/>}
        {auth.isAuthorized() && <Route path="/Profile/Preferences" element={<PreferencesPage/>}/>}
        {auth.isAuthorized() && <Route path="/Profile/CardLayout" element={<EditCardPage/>}/>}
        {auth.isAuthorized() && <Route path="/Profile" element={<EditProfilePage/>}/>}
        {auth.isAuthorized() && <Route path="/Store" element={<StorePage/>}/>}
        {auth.isAuthorized() && <Route path="/Friends" element={<FriendsPage/>}/>}
        <Route path="*" element={<Background><Error404/></Background>}/>
    </Routes>
}
