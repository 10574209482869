import {Button, TextField} from "@mui/material";
import LobbySection from "./LobbySection";
import {useSnackbar} from "notistack";
import {useState} from "react";
import {postObject} from "../../../functions/objectService";
import {useTranslation} from "react-i18next";

export default function InviteByEmail({gameToken}:{gameToken:number|string}) {
    const notification = useSnackbar()
    const [email, setEmail] = useState("")
    const {t} = useTranslation()

    async function handleInvite() {
        try {
            if(!email.includes("@") || !email.includes(".") || email.length <= 4) {
                notification.enqueueSnackbar(t("lobby.friends.email.validError"), {variant: "error"})
                return;
            }
            await postObject("/game/invites/email", {email: email, gameToken: gameToken})
            notification.enqueueSnackbar(t("lobby.friends.successfullyInvited", {name: email}), {variant: "success"})
            setEmail("")
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <LobbySection title={t("lobby.friends.email.title")}>
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <TextField size={"small"} label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button onClick={handleInvite} sx={{height: "100%", ml: 1}} variant={"contained"}>
                {t("lobby.friends.email.inviteBtn")}
            </Button>
        </div>
    </LobbySection>
}
