import {Avatar, Divider} from "@mui/material";
import ActionInfo from "../../../types/ActionInfo";
import PlayerInfo from "../../../types/PlayerInfo";
import {useTranslation} from "react-i18next";
import {ActionType} from "../../../types/ActionType";
import {BOT_NAME} from "../../../util/GameUtil";

interface ActionDetailProps {
    action: ActionInfo
    player: PlayerInfo
}


export default function ActionDetail({action, player}: ActionDetailProps) {
    const {t} = useTranslation()

    function getActionText() {
        switch (action.type) {
            case ActionType.BUILD:
                return t("game.action-detail.action-build")
            case ActionType.MOVE:
                return t("game.action-detail.action-move")
            case ActionType.ADD_BUILDER:
                return t("game.action-detail.action-add-builder")
        }
    }

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Avatar sx={{width: 20, height: 20, marginLeft: 1.4}}
                        src={player.user === null ? "/images/bot/bot.png" : player.user?.avatarUrl} alt={player.user?.nickname}></Avatar>
                <div
                    style={{
                        paddingTop: 5,
                        paddingLeft: 5,
                        paddingBottom: 5,
                    }}>
                    <strong>{player.user === null ? BOT_NAME : player.user?.nickname}</strong>{" " + getActionText()}
                </div>
            </div>
            <Divider></Divider>
        </>)
}
