import FieldInfo from "../../../types/FieldInfo";
import React from "react";
import BuilderMale from "../../threejs/builders/BuilderMale";
import BuilderFemale from "../../threejs/builders/BuilderFemale";
import {DEFAULT_HEIGHT, LEVEL_1_HEIGHT, LEVEL_2_HEIGHT, LEVEL_3_HEIGHT} from "../../../util/GameUtil";

export interface BuilderProps {
    field: FieldInfo
    handlePawnClick?: (builderId: number | undefined) => void
}

function getZ(field: FieldInfo): number {
    switch (field.towerSize) {
        case 3:
            return LEVEL_3_HEIGHT
        case 2:
            return LEVEL_2_HEIGHT
        case 1:
            return LEVEL_1_HEIGHT
        default:
            return DEFAULT_HEIGHT
    }
}

export default function Builder({field, handlePawnClick}: BuilderProps) {

    return (<>
        {field.builder !== null && field.builder.gender === "Male" &&
            <BuilderMale position={[field.x, getZ(field), field.y]} handlePawnClick={handlePawnClick}
                         builderId={field.builder.id} color={field.builder.player.color}
            />}
        {field.builder !== null && field.builder.gender === "Female" &&
            <BuilderFemale position={[field.x, getZ(field), field.y]} handlePawnClick={handlePawnClick}
                           builderId={field.builder.id} color={field.builder.player.color}
            />}
    </>)

}
