import React from "react";
import {StoreCardList} from "./StoreCard";



export default function BoughtLibrary() {
   const url = "/achievement/allBoughtAchievements";


    return(
            <StoreCardList  url={url}/>
    )
}
