import {Button, Grid, Typography} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import GameTitle from "../components/particles/game/GameTitle";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

export default function AnonymousHomePage() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return <>
        <Grid container spacing={1} className={"flexElements spaceBetweenElements"}>
            <Grid item sx={{visibility: "hidden"}}>
                <Button sx={{mt: 2, mr: 2}} startIcon={<LockIcon/>}>{t("home.login")}</Button>
            </Grid>
            <Grid item sx={{textAlign: "center", opacity: "90%"}}>
                <GameTitle/>
                <Typography variant={"body2"}>{t("home.authorizeYourself")}</Typography>
            </Grid>
            <Grid item>
                <Button onClick={() => navigate("/login")} sx={{mt: 2, mr: 2}}
                        startIcon={<LockIcon/>}>{t("home.login")}</Button>
            </Grid>
        </Grid>
    </>
}
