import useAuth from "../../../hooks/useAuth";
import {useSnackbar} from "notistack";
import React from "react";
import {useGetCollection} from "../../../hooks/useGetObject";
import Achievement from "../../../types/Achievement";
import {putObjectWithResponse} from "../../../functions/objectService";
import {Box, Button, Card, Grid, Typography} from "@mui/material";
import Coins from "../../../media/img/Coin.png";
import {ReturnRightAchievementType} from "./StoreCard";
import {useTranslation} from "react-i18next";

let keyCounter = 0
export default function BuyableItemsLibrary() {
    const auth = useAuth();
    const { t } = useTranslation();
    const notification = useSnackbar();
    const {
        data: items,
        refresh
    } = useGetCollection<Achievement>("/achievement/allAchievements");

     function checkIfBuyable(price:number) {
        if(auth.user?.rewardtokens!==undefined){
            if (auth.user?.rewardtokens<price) return false
        }
        return true
    }
     async function buy(avatar:Achievement) {
        const achievement = {
            achievementId : avatar.id
        };
        try {
            let response = await putObjectWithResponse<{ achievementId:number}, string>("/achievement/buy",achievement);
            await refresh();
            notification.enqueueSnackbar(`Item bought succesfully`, {variant: "success"});
            auth.setToken(response.data)
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to buy your item.", {variant: "error"});
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }
    const achievementCards= items.map(achievement => {
                        return <Grid key={keyCounter++} item xs={12} sm={4} md={4}>
                            <Card sx={{height:450}}>
                               <ReturnRightAchievementType achievement={achievement} user={auth.user} name={achievement.name}/>
                                <Box component={"div"} sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 200,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    padding: 2
                                }}>
                                    <Typography variant={"h4"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                                                fontWeight={"bold"}
                                                sx={{textAlign: "center"}}>{achievement.name}</Typography>
                                    <Typography variant={"body1"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                                                fontWeight={"bold"}
                                                sx={{textAlign: "center"}}>{achievement.achievementType}</Typography>
                                    <Typography variant={"h5"} fontFamily={"'Caesar Dressing', cursive"} color={"black"}
                                                sx={{textAlign: "right"}}>{achievement.pointsPrice}<img alt={"coins"} style={{
                                        marginTop: 3,
                                        height: 30,
                                        width: 30
                                    }} src={Coins}/></Typography>

                                    <Button sx={{display: "block"}} variant="contained"
                                            disabled={!checkIfBuyable(achievement.pointsPrice)}
                                            onClick={() => buy(achievement)}>{t("store.buy")}</Button>
                                </Box>
                            </Card>
                        </Grid>
    });
    return(
            <Grid container sx={{marginTop:10,padding:"5%" ,paddingBottom:"15%"}} spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                {achievementCards}
            </Grid>
    )
}
