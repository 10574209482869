import * as THREE from 'three'
import React from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import LevelProps from "../../../types/ThreeJsProps";
import {LEVEL_SCALE} from "../../../util/GameUtil";

type GLTFResult = GLTF & {
    nodes: {
        Lvl3: THREE.Mesh
    }
    materials: {
        ['plaster old house']: THREE.MeshStandardMaterial
    }
}

export default function Level3({position}: LevelProps) {
    const {nodes, materials} = useGLTF('/board/level3.glb') as unknown as GLTFResult
    return (
        <group scale={LEVEL_SCALE} position={position} dispose={null}>
            <mesh geometry={nodes.Lvl3.geometry} material={materials['plaster old house']} position={[0, 5.92, 0]}
                  rotation={[Math.PI / 2, 0, 0]}/>
        </group>
    )
}

useGLTF.preload('/board/level3.glb')
