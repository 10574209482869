import {deleteObject, postObject} from "../functions/objectService";
import {useNavigate} from "react-router-dom";

interface useGameLobbyProps {
    tokenId: string | undefined
    refetchGameStatus: () => void;
    refetchTurns : () => void;
}


export function useGameLobby({tokenId, refetchGameStatus, refetchTurns}: useGameLobbyProps) {

    const navigate = useNavigate()

    async function handleKick(userId: number) {
        await deleteObject<number>(`/game/kick/${tokenId}/${userId}`)
    }

    async function handleLeaveGame() {
        await deleteObject<number>(`/game/leave/${tokenId}`)
        navigate("/")
    }

    async function handleStartGame() {
        await postObject("/game/start", {gameToken: tokenId})
        await refetchGameStatus()
        await refetchTurns()
    }


    async function inviteUser(userId: number) {
        await postObject("/game/invites/invite", {gameToken: tokenId, userId: userId})
    }

    return {
        handleKick,
        handleLeaveGame,
        handleStartGame,
        inviteUser,
    }
}
