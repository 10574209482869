import {LinearProgress, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import useInterval from "../../../hooks/useInterval";
import {datePlusMs, secondsBetween} from "../../../functions/date";
import GameContext from "../../../context/GameContext";


export default function Timer() {
    const [progress, setProgress] = useState(0)
    const [seconds, setSeconds] = useState(0)
    useInterval(checkProgress, 0.1)
    const gameContext = useContext(GameContext)

    function checkProgress() {
        if (gameContext.game.turnStartedOn !== null && gameContext.game.winner === null) {
            const dateNow = new Date()
            const dateTurnEnds = datePlusMs(gameContext.game.turnStartedOn, gameContext.game.turnDurationInSeconds * 1000)
            const timeLeft = secondsBetween(dateNow, dateTurnEnds)
            if (Math.floor(timeLeft) !== seconds) {
                setSeconds(Math.floor(timeLeft))
            }
            if (Math.floor(timeLeft) <= 0) {
                gameContext.resetCirclesAndButtons()
            }
            if (timeLeft < 0) setProgress(100)
            else setProgress(100 - (timeLeft / gameContext.game.turnDurationInSeconds) * 100)
        }
    }

    return (<>
        <div style={{
            position: "absolute",
            display: "flex",
            top: 120,
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            alignItems: "center"
        }}>
            {progress > 0 && progress < 100 &&
                <Typography style={{marginBottom: 0}}>{seconds}</Typography>}
            {progress > 0 && progress < 100 &&
                <LinearProgress style={{color: "red"}} sx={{width: 300, color: 'red'}} variant="determinate"
                                value={progress}/>}
        </div>
    </>)
}