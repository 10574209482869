import Background from "../components/particles/Background";
import {Box} from "@mui/system";
import React, {useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import GameTitle from "../components/particles/game/GameTitle";
import {useNavigate} from "react-router-dom";
import {postObjectWithResponse} from "../functions/objectService";
import {useSnackbar} from "notistack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";

export default function SendEmailForPasswordReset() {
    const notification = useSnackbar();
    const [credentials, setCredentials] = useState({email: ""});
    const navigate = useNavigate();
    const {t} = useTranslation();

    function handleChange(attribute: string, value: string) {
        setCredentials({...credentials, [attribute]: value})
    }

    async function onSubmit() {
        try {
            await postObjectWithResponse("/reset/sendMail", credentials);
            notification.enqueueSnackbar(`Mail sent`, {variant: "success"});
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to send mail. Do you have an account on this app?", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return (
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Background>
                <Box
                    component="form"
                    noValidate
                    sx={{maxWidth: 800, overflowY: "auto"}}
                    autoComplete="off"
                >
                    <GameTitle/>
                    <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                                fontWeight={"bold"}
                                sx={{textAlign: "center"}}>{t("sendmailforpasswordreset.sendresetmailtitle")}</Typography>
                    <Button onClick={() => navigate("/login")}
                            sx={{mt: 2, mr: 2, position: "absolute", left: 0, top: 0}} startIcon={<ArrowBackIcon/>}>Go
                        back</Button>
                    <TextField
                        fullWidth
                        margin={"dense"}
                        className="textfieldLogin"
                        label={t("sendmailforpasswordreset.email")}
                        variant="filled"
                        value={credentials.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                    />
                    <Button onClick={onSubmit} variant="contained">{t("sendmailforpasswordreset.sendemail")}</Button>
                </Box>
            </Background>
        </div>
    )
}
