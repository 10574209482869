import LeaveGameButton from "../lobby/LeaveGameButton";
import GameTitle from "./GameTitle";
import Participants from "../lobby/Participants";
import InviteFriends from "../lobby/InviteFriends";
import InviteByEmail from "../lobby/InviteByEmail";
import Error404 from "../../../pages/errors/Error404";
import {useParams} from "react-router-dom";
import {useGame} from "../../../pages/GamePage";
import {Button} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {useTranslation} from "react-i18next";
import InviteList from "../lobby/InviteList";

export default function BeforeGame() {
    const {tokenId} = useParams<{ tokenId: string }>()
    const gameContext = useGame()
    const auth = useAuth()
    const {t} = useTranslation()

    if (tokenId === undefined) return <Error404/>

    return <>
        <div style={{padding: 5}}>
            <div style={{position: "absolute", top: 12, left: 12}}>
                <LeaveGameButton/>
            </div>
            <div className={"flexElements centerElements verticalAlignCenter"}
                 style={{height: "80vh", minWidth: 100}}>
                <div>
                    <GameTitle marginTop={2}/>
                    <div style={{background: "rgb(255,255,255,0.7)", padding: 5, borderRadius: 10, marginTop: 20}}>
                        <Participants players={gameContext.game.players}/>
                        {auth.user?.id === gameContext.game.host.id && <div className={"flexElements centerElements"}>
                            <Button sx={{my: 1, mt: 3}} variant={"contained"}
                                    onClick={gameContext.handleStartGame}>{t("lobby.startGameBtn")}</Button>
                        </div>}
                        <InviteByEmail gameToken={tokenId}/>
                        <InviteFriends gameToken={tokenId}/>
                        <InviteList gameToken={tokenId}/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

