import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {postObjectWithResponse} from "../../../functions/objectService";
import Game from "../../../types/Game";
import {useNavigate} from "react-router-dom";

export default function CreateSinglePlayerGameButton() {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    return <>
        <Button variant={"contained"} onClick={() => setOpen(true)} fullWidth>{t("home.singlePlayer.createSinglePlayerGame")}</Button>
        <CreateSinglePlayerGameDialog open={open} handleClose={() => setOpen(false)} />
    </>
}

function CreateSinglePlayerGameDialog({open, handleClose}:{open:boolean, handleClose:() => void}) {
    const {t} = useTranslation()
    const [useGods, setUseGods] = useState(true)
    const [turnDuration, setTurnDuration] = useState<20|30|45>(30)
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()

    async function handleSubmit() {
        try {
            const response = await postObjectWithResponse<{}, Game>("/game/create/singleplayer", {
                useGods: useGods,
                turnDuration: turnDuration
            })
            enqueueSnackbar(t("home.singlePlayer.createdAGame"), {variant: "success"})
            navigate(`/game/${response.data.linkToken}`)
        } catch (e:unknown) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("home.singlePlayer.playAgainstAI")}</DialogTitle>
        <DialogContent>
            <FormLabel>{t("home.singlePlayer.useGods")}</FormLabel>
            <div className={"flexElements"} style={{marginBottom: 20}}>
                <OptionDiv active={useGods} text={"Enable"} setValue={() => setUseGods(true)} />
                <OptionDiv active={!useGods} text={"Disable"}
                           setValue={() => setUseGods(false)}/>
            </div>
            <FormLabel>{t("home.singlePlayer.difficulty")}</FormLabel>
            <div className={"flexElements"}>
                <OptionDiv active={turnDuration === 20} text={"Easy"} setValue={() => setTurnDuration(20)} />
                <OptionDiv active={turnDuration === 30} text={"Medium"} setValue={() => setTurnDuration(30)} />
                <OptionDiv active={turnDuration === 45} text={"Hard"} setValue={() => setTurnDuration(45)} />
            </div>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={handleClose}>{t("home.singlePlayer.cancelGame")}</Button>
            <Button onClick={handleSubmit}>{t("home.singlePlayer.startGame")}</Button>
        </DialogActions>
    </Dialog>
}

function OptionDiv({text, setValue, active}:{active: boolean, text:React.ReactNode, setValue:() => void}) {
    const otherSx = {borderRadius: 17, border: "3px solid #8c9058", padding: 10, paddingTop: 9, paddingLeft: 15, paddingRight: 15, margin: 3, cursor: "pointer"}
    const activeSx = {...otherSx, border: "3px solid #0F4E9D"}

    return <div style={active ? activeSx : otherSx} onClick={setValue}>
        <Typography variant={"h6"} fontSize={18}>{text}</Typography>
    </div>
}
