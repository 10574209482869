import Players from "./Players";
import Actions from "./Actions";
import TurnInfo from "../../../types/TurnInfo";
import God from "../../../types/God";
import {useContext} from "react";
import GameContext from "../../../context/GameContext";

export interface ProfileParams {
    playerId: number
    color: string
    url: string
    name: string
    god: God
    hasTurn: boolean
}

interface SideInfoProps {
    currentTurn: TurnInfo | undefined
}

export default function SideInfo({currentTurn}: SideInfoProps) {
    const gameContext = useContext(GameContext)
    return (<div style={{position: "absolute", right: 20, top: 20}}>
        <Players players={gameContext.game.players} playerId={gameContext.game.playerId} currentTurn={currentTurn}/>
        <Actions turns={gameContext.turns}/>
    </div>)
}