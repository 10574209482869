import useGetSingle from "../../../hooks/useGetObject";
import Bronze from "../../../media/img/StarIconBronze.png"
import Silver from "../../../media/img/Silver_star.png";
import Gold from "../../../media/img/gold_start.png";
import Crystal from "../../../media/img/crystal_star.jpg"

export default function StatusComponent() {
    const {
        data: status
    }= useGetSingle("/friends/getStatus","BRONZE");

    switch (status) {
        case 'SILVER':
            return <img style={{paddingLeft:5, paddingRight:5}} src={Silver} height={20} width={20} alt="silver"/>;
        case 'GOLD':
            return  <img style={{paddingLeft:5, paddingRight:5}} src={Gold} height={20} width={20} alt="gold"/>;
        case 'CRYSTAL':
            return <img style={{paddingLeft:5, paddingRight:5}} src={Crystal} height={20} width={20} alt="crystal"/>;
        default:
            return <img style={{paddingLeft:5, paddingRight:5}} src={Bronze} height={20} width={20} alt="bronze"/>;
    }
}
