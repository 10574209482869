import * as THREE from 'three'
import React, {useRef} from 'react'
import {useFrame} from "@react-three/fiber";
import Island from "../threejs/board/Island";
import Level1 from "../threejs/board/Level1";
import Level2 from "../threejs/board/Level2";
import Level3 from "../threejs/board/Level3";
import Dome from "../threejs/board/Dome";
import BuilderFemale from "../threejs/builders/BuilderFemale";
import BuilderMale from "../threejs/builders/BuilderMale";
import {DEFAULT_HEIGHT, LEVEL_1_HEIGHT, LEVEL_2_HEIGHT, LEVEL_3_HEIGHT} from "../../util/GameUtil";

export default function RotatingIsland() {

    const island = useRef<THREE.Group>(null!);

    useFrame(() => {
        island.current!.rotation.y -= 0.002;
    });

    return (
        <group dispose={null} ref={island}>
            <Island position={[0, -0.0225, 0]}/>
            <gridHelper args={[5, 5, "white", "white"]}/>
            <Level1 position={[0, DEFAULT_HEIGHT, 1]}/>
            <Level1 position={[0, DEFAULT_HEIGHT, 0]}/>
            <Level1 position={[-1, DEFAULT_HEIGHT, 0]}/>
            <Level1 position={[-1, DEFAULT_HEIGHT, 1]}/>
            <Level1 position={[2, DEFAULT_HEIGHT, 2]}/>
            <Level2 position={[2, LEVEL_1_HEIGHT, 2]}/>
            <Level1 position={[1, DEFAULT_HEIGHT, 2]}/>
            <Level1 position={[2, DEFAULT_HEIGHT, 1]}/>
            <Level1 position={[2, DEFAULT_HEIGHT, -2]}/>
            <Level1 position={[2, DEFAULT_HEIGHT, -1]}/>
            <Level1 position={[1, DEFAULT_HEIGHT, -2]}/>
            <Level1 position={[0, DEFAULT_HEIGHT, -2]}/>
            <Level2 position={[2, LEVEL_1_HEIGHT, -2]}/>
            <BuilderFemale position={[-2, DEFAULT_HEIGHT, 0]} color={"Green"}/>
            <BuilderFemale position={[-1, LEVEL_2_HEIGHT, 1]} color={"Pink"}/>
            <BuilderMale position={[-1, DEFAULT_HEIGHT, 2]} color={"Green"}/>
            <BuilderMale position={[0, LEVEL_1_HEIGHT, -2]} color={"Pink"}/>
            <Level2 position={[0, LEVEL_1_HEIGHT, 0]}/>
            <Level2 position={[-1, LEVEL_1_HEIGHT, 1]}/>
            <Level3 position={[0, LEVEL_2_HEIGHT, 0]}/>
            <Dome position={[0, LEVEL_3_HEIGHT, 0]}/>
        </group>
    )
}
