import {useNavigate} from "react-router-dom";
import {Button, Card, Typography} from "@mui/material";
import {KeyboardArrowLeft} from "@mui/icons-material";
import {useGetCollection} from "../hooks/useGetObject";
import AwardedAwardToken from "../types/AwardedAwardToken";
import GameTitle from "../components/particles/game/GameTitle";
import Coins from "../media/img/Coin.png";
import React from "react";
import {postObjectWithResponse} from "../functions/objectService";
import {useSnackbar} from "notistack";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import useAuth from "../hooks/useAuth";
import ScalableSideInfo from "../components/particles/game/ScalableSideInfo";


export default function AwardedAwardTokens() {
    const auth = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const notification = useSnackbar();
    const {
        data: items,
        refresh
    } = useGetCollection<AwardedAwardToken>("/achievement/awardToken/all");

    async function onSubmit(award: AwardedAwardToken) {
        try {
            var response = await postObjectWithResponse<AwardedAwardToken, string>("/achievement/awardToken/getAwardToken", award);
            auth.setToken(response.data);
            await refresh();
            notification.enqueueSnackbar(`Award claimed`, {variant: "success"})
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Award failed to claim.", {variant: "error"});
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    const awards = items.map(award => {
        return <Card sx={{padding: 2, margin: 2}}>
            <Typography variant={"h5"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}>
                {award.name}
            </Typography>
            <Typography variant={"h5"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}>
                {award.amount}
                <img alt={"coins"} style={{height: 30, width: 30}} src={Coins}/>
            </Typography>
            <Button sx={{float: "right"}} onClick={() => onSubmit(award)} disabled={award.awarded}
                    variant={"contained"}>Take</Button>
        </Card>
    })
    return <>
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Box component="div" sx={{maxWidth: 1000}}>
                <ScalableSideInfo isForGame={false}/>
                <Button startIcon={<KeyboardArrowLeft/>} variant={"contained"}
                        sx={{position: "absolute", left: 5, top: 10}} onClick={() => navigate("/profile")}>Go
                    back</Button>
                <GameTitle/>
                <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("awardedawardtokens.claimtokens")}</Typography>

                {awards}
            </Box>
        </div>
    </>
}