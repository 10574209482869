import {Grid} from "@mui/material";
import React from "react";
import GameTitle from "../components/particles/game/GameTitle";
import JoinGame from "../components/particles/home/JoinGame";
import LiveGameData from "../components/particles/home/LiveGameData";
import HostGameButton from "../components/particles/home/HostGameButton";
import CreateSinglePlayerGameButton from "../components/particles/home/CreateSinglePlayerGameButton";

export default function AuthorizedHomePage() {

    return <Grid container spacing={1} className={"flexElements spaceBetweenElements"}>
        <Grid item>

        </Grid>
        <Grid item sx={{textAlign: "center", opacity: "90%"}}>
            <GameTitle/>
            <div style={{marginTop: 20}} className={"flexElements centerElements"}>
                <div style={{width: "fit-content"}}>
                    <div>
                        <JoinGame/>
                        <HostGameButton/>
                        <CreateSinglePlayerGameButton/>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid item>
            <LiveGameData/>
        </Grid>
    </Grid>

}
