import {useCallback, useEffect, useMemo, useState} from "react"
import {getObject} from "../functions/objectService";

export default function useGetSingle<T>(url: string, defaultValue: T) {
    const [loading, setIsLoading] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [fetchedData, setFetchedData] = useState<T>(defaultValue)

    const getData = useCallback(async () => {
        try {
            const {data} = await getObject<T>(url)
            setFetchedData(data)
            if(errorMsg.length > 0) setErrorMsg("")
        } catch (error: any) {
            setErrorMsg(error + "")
            console.log("useGetSingle has thrown an error: " + error)
        } finally {
            setIsLoading(false)
            setHasLoaded(true)
        }
    }, [url, errorMsg.length])

    useEffect(() => {
        getData()
    }, [getData, url]);

    const error = useMemo((): boolean => {
        return errorMsg.length > 0
    }, [errorMsg])

    async function refresh() {
        setIsLoading(true)
        await getData()
        return {loading, "data": fetchedData, refresh, error, errorMsg, hasLoaded: true}
    }

    return {loading, "data": fetchedData, refresh, error, errorMsg, hasLoaded}
}


export function useGetCollection<T>(url: string) {
    return useGetSingle<Array<T>>(url, [])
}
