import * as THREE from 'three'
import React from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import {BuilderProps} from "../../../types/ThreeJsProps";
import {
    BUILDER_POSITION_X,
    BUILDER_POSITION_Y,
    BUILDER_POSITION_Z,
    BUILDER_ROTATION_X,
    BUILDER_ROTATION_Y,
    BUILDER_ROTATION_Z,
    BUILDER_SCALE
} from "../../../util/GameUtil";

type GLTFResult = GLTF & {
    nodes: {
        ['Builder-Male']: THREE.Mesh
    }
    materials: {
        ['Material.001']: THREE.MeshStandardMaterial
    }
}

function getPath(color: string | undefined) {
    if (color === undefined) {
        return '/builders/male/male_green.glb'
    }
    return "/builders/male/male_" + color.toLowerCase() + ".glb"
}

export default function BuilderMale({position, handlePawnClick, builderId, color}: BuilderProps) {
    const {nodes, materials} = useGLTF(getPath(color)) as unknown as GLTFResult
    return (
        <group scale={BUILDER_SCALE} position={position} dispose={null} onClick={() => {
            if (handlePawnClick) {
                handlePawnClick(builderId)
            }
        }}>
            <mesh geometry={nodes['Builder-Male'].geometry} material={materials['Material.001']}
                  position={[BUILDER_POSITION_X, BUILDER_POSITION_Y, BUILDER_POSITION_Z]}
                  rotation={[BUILDER_ROTATION_X, BUILDER_ROTATION_Y, BUILDER_ROTATION_Z]}/>
        </group>
    )
}

useGLTF.preload('/builders/male/male_yellow.glb')
useGLTF.preload('/builders/male/male_red.glb')
useGLTF.preload('/builders/male/male_green.glb')
useGLTF.preload('/builders/male/male_pink.glb')
