import axios, {AxiosResponse} from "axios"


axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`

setAuthHeader()

export function setAuthHeader() {
    const localUserToken = localStorage.getItem("authToken") ?? ""
    if (localUserToken !== null && localUserToken.length > 5 && localUserToken !== "undefined") axios.defaults.headers.common["Authorization"] = `Bearer ${localUserToken}`
    else delete axios.defaults.headers.common["Authorization"]
}

export async function getObject<T>(path: string) {
    try {
        return await axios.get<T>(path)
    } catch (e) {
        throw e
    }
}

export async function putObject<T>(path: string, entity: T) {
    return await axios.put<T>(path, entity)
}

export async function putObjectWithResponse<T, R>(path: string, entity: T) {
    return await axios.put<T, AxiosResponse<R>>(path, entity)
}

export async function postObject<T>(path: string, entity: T) {
    return await axios.post<T>(path, entity)
}

export async function postObjectWithResponse<T, R>(path: string, entity: T) {
    return await axios.post<T, AxiosResponse<R>>(path, entity)
}

export async function postObjectWithResponseWithHeader<T, R>(path: string, entity: T, header: {} = {}) {
    return await axios.post<T, AxiosResponse<R>>(path, entity, header)
}

export async function deleteObject<T>(path: string) {
    return await axios.delete<T>(path)
}
