import {Button, Card} from "@mui/material";
import {ActionType} from "../../../types/ActionType";
import {useTranslation} from "react-i18next";
import ActionInfo from "../../../types/ActionInfo";
import {useContext} from "react";
import GameContext from "../../../context/GameContext";
import TurnInfo from "../../../types/TurnInfo";
import {BOT_NAME} from "../../../util/GameUtil";

interface SideInfoProps {
    currentTurn: TurnInfo | undefined
}


export default function GameStatus({currentTurn}: SideInfoProps) {
    const {t} = useTranslation()
    const gameContext = useContext(GameContext)

    function getMessage(): string {
        let currentAction = currentTurn?.actions.sort((a, b) => a.id - b.id).reverse().at(0)
        if (currentAction !== undefined) {
            if (currentTurn?.player.id === gameContext.game.playerId) {
                return getOwnStatus(currentAction)
            } else {
                return getOtherStatus(currentAction)
            }
        }
        return t("game.status.no-action")

    }

    function getOtherStatus(currentAction: ActionInfo): string {
        switch (currentAction.type) {
            case ActionType.MOVE:
                return `${(currentTurn?.player.user?.nickname) ?? BOT_NAME} ${t("game.status.is-moving")}`
            case ActionType.BUILD:
                return `${(currentTurn?.player.user?.nickname) ?? BOT_NAME} ${t("game.status.is-building")}`
            case ActionType.ADD_BUILDER:
                return `${(currentTurn?.player.user?.nickname) ?? BOT_NAME} ${t("game.status.is-adding-builder")}`
        }
        return t("game.status.no-action")
    }

    function getOwnStatus(currentAction: ActionInfo): string {
        switch (currentAction.type) {
            case ActionType.MOVE:
                if (gameContext.builderId === undefined) {
                    return t("game.status.choose-piece")
                } else {
                    return t("game.status.choose-move")
                }
            case ActionType.BUILD:
                return t("game.status.choose-build")
            case ActionType.ADD_BUILDER:
                if (currentAction.toField !== undefined) {
                    return t("game.status.choose-add-builder")
                }
        }
        return t("game.status.no-action")
    }


    return (<>
        <div style={{
            position: "absolute",
            display: "flex",
            top: 10,
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            alignItems: "center"
        }}>
            <Card sx={{
                background: "linear-gradient(180deg, #eacda3, #d6ae7b)",
                top: 10,
                minWidth: 300,
                minHeight: 50,
                borderRadius: '8px',
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center"

            }}>
                {currentTurn !== undefined ? <div style={{fontSize: 20}}><span
                    style={{
                        color: "blue",
                        fontWeight: "bold",
                        paddingLeft: 15,
                        paddingRight: 15,
                        fontSize: 20
                    }}>{getMessage()}</span>
                </div> : <div style={{fontSize: 20}}>{t("game.status.no-start")}</div>}
            </Card>
            <div style={{display: "flex", flexDirection: "row"}}>
                {gameContext.resetButtonActive && <Button variant="contained"
                                                          style={{
                                                              background: "linear-gradient(180deg, #eacda3, #d6ae7b)",
                                                              color: "red",
                                                              fontWeight: "bold"
                                                          }} onClick={gameContext.handleResetButtonClick}>Reset
                    turn</Button>}
                {gameContext.passGodActionButtonActive && <Button variant="contained"
                                                                  style={{
                                                                      background: "linear-gradient(180deg, #eacda3, #d6ae7b)",
                                                                      color: "blue",
                                                                      fontWeight: "bold"
                                                                  }} onClick={gameContext.handlePassGodButtonClick}>Pass
                    god
                    action</Button>}

            </div>


        </div>
    </>)
}
