import {useState} from "react";

export enum Preference {
    SoundEffects = 0,
    NotificationsOnTurnStart = 1,
    UseDutch = 2
}

export interface UsePreferenceProps {
    value: boolean
    set: (e:boolean) => void
    toggle: () => void
}

const prefix = "santoriniPreference"
const defaultVal = "1"
export default function usePreference(pref:Preference) {
    const [value, setValue] = useState(get())

    function toggle() {
        set(!get())
        setValue(get())
        return value
    }

    function set(val:boolean) {
        localStorage.setItem(getPreferenceKey(pref), val ? "1" : "0")
        setValue(val)
        return get()
    }

    function get() : boolean {
        return (localStorage.getItem(getPreferenceKey(pref)) ?? defaultVal) === "1"
    }

    return {
        toggle,
        set,
        value
    }
}

function getPreferenceKey(pref:Preference) {
    return `${prefix}_${pref}`
}
