import {Button, FormControlLabel, FormGroup, FormLabel, Paper, Switch, Typography} from "@mui/material";
import {UsePreferenceProps} from "../hooks/usePreference";
import usePreferences from "../hooks/usePreferences";
import {useNavigate} from "react-router-dom";
import {KeyboardArrowLeft} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function PreferencesPage() {
    const preferenceOptions = usePreferences()
    const navigate = useNavigate()
    const {t} = useTranslation()

    return <div className={"flexElements centerElements"}>
        <div style={{minWidth: 370, maxWidth: "100%"}}>
            <Button startIcon={<KeyboardArrowLeft/>} variant={"contained"} sx={{my: 2}}
                    onClick={() => navigate("/profile")}>{t("preferences.goBack")}</Button>
            <Paper sx={{p: 2}}>
                <Typography variant={"h4"} sx={{mb: 2}}>{t("preferences.preferences")}</Typography>
                <PreferenceToggle info={t("preferences.soundEffects")} pref={preferenceOptions.SoundEffects}/>
                <PreferenceToggle info={t("preferences.useNotifications")}
                                  pref={preferenceOptions.NotificationsOnTurnStart}/>
                <ToggleLanguage/>
            </Paper>
        </div>
    </div>
}

function PreferenceToggle({info, pref}: { info: string, pref: UsePreferenceProps }) {
    return <FormGroup>
        <FormControlLabel labelPlacement={"end"} control={
            <Switch checked={pref.value} size={"small"} onClick={pref.toggle}/>
        } label={info}/>
    </FormGroup>
}

function ToggleLanguage() {
    const {UseDutch} = usePreferences()
    const {t} = useTranslation()

    return <>
        <FormLabel sx={{display: "block", pb: 0, mb: 0, mt: 3}}>{t("preferences.language")}</FormLabel>
        <CountryFlag url={"https://cdn-icons-png.flaticon.com/512/330/330520.png"} alt={"BE"} active={UseDutch.value}
                     enable={() => UseDutch.set(true)}/>
        <CountryFlag url={"https://cdn-icons-png.flaticon.com/512/3013/3013972.png"} alt={"EN"} active={!UseDutch.value}
                     enable={() => UseDutch.set(false)}/>
    </>
}

function CountryFlag({url, alt, active, enable}: { url: string, alt: string, active: boolean, enable: () => void }) {
    const activeSx = active ? {} : {filter: "grayscale(90%)"}
    const sx = {height: 50, marginRight: 10, cursor: "pointer", ...activeSx}
    return <img onClick={enable} style={sx} src={url} alt={alt}/>
}
