import * as THREE from 'three'
import React from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import LevelProps from "../../../types/ThreeJsProps";
import {ISLAND_SCALE, ISLAND_SCALE_ISLAND} from "../../../util/GameUtil";

type GLTFResult = GLTF & {
    nodes: {
        Island: THREE.Mesh
        Board: THREE.Mesh
    }
    materials: {
        ['Material.Island']: THREE.MeshStandardMaterial
        Material: THREE.MeshStandardMaterial
    }
}

export default function Island({position}: LevelProps) {
    const {nodes, materials} = useGLTF('/board/island.glb') as unknown as GLTFResult
    return (
        <group scale={ISLAND_SCALE} position={position} dispose={null}>
            <group scale={ISLAND_SCALE_ISLAND}>
                <mesh geometry={nodes.Island.geometry} material={materials['Material.Island']}
                      position={[2.97, -21.77, 6.13]} scale={10}/>
            </group>
            <mesh geometry={nodes.Board.geometry} material={materials.Material} position={[0, -0.03, 0]}
                  rotation={[0, 0, -0.01]} scale={0.96}/>
        </group>
    )
}

useGLTF.preload('/board/island.glb')
