import {useNavigate} from "react-router-dom";
import {postObjectWithResponse} from "../functions/objectService";
import Player from "../types/Player";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

export default function useGameInvite() {
    const snackbar = useSnackbar()
    const navigate = useNavigate()
    const {t} = useTranslation()

    async function join(token:string) {
        try {
            const response = await postObjectWithResponse<{ gameToken: string }, Player>("/game/player", {gameToken: token})
            const data = response.data
            snackbar.enqueueSnackbar(t("hooks.successfullyJoinedGame", {color: data.color}), {variant: "success"})
            navigate(`/game/${token}`)
        } catch (e) {
            snackbar.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return join
}
