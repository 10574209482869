import {useEffect, useState} from "react";
import axios from "axios";
import TurnInfo from "../types/TurnInfo";
import {ResponseType} from "../types/ResponseType";
import ActionInfo from "../types/ActionInfo";
import useStateRef from "react-usestateref";

interface TurnHookProps {
    url: string
    actionUrl: string
    updateTurn: (turn: TurnInfo, actionType: ResponseType, nextTurn: TurnInfo | undefined | null) => void
    reset: () => void
}

export default function useTurnsFromGame({url, updateTurn, reset}: TurnHookProps) {
    const [loading, setIsLoading] = useState(true);
    const [error, setIsError] = useState(false);
    const [turns, setFetchedTurns] = useStateRef<TurnInfo[]>([]);

    async function refetch() {
        async function getData() {
            try {
                const {data} = await axios.get<TurnInfo[]>(url);
                const lastTurnIdFromData = data.sort((a, b) => a.id - b.id).reverse().at(0)?.id
                const lastTurnIdFromFetchedData = turns.sort((a, b) => a.id - b.id).reverse().at(0)?.id
                if (lastTurnIdFromData !== undefined && lastTurnIdFromFetchedData !== undefined) {
                    if (lastTurnIdFromData !== lastTurnIdFromFetchedData) {
                        reset()
                    }
                }
                setFetchedTurns(data);
                let previousTurn = data.sort((a, b) => a.id - b.id).reverse().at(1)
                let actualTurn = data.sort((a, b) => a.id - b.id).reverse().at(0)
                if (previousTurn === undefined) {
                    previousTurn = actualTurn
                }
                if (previousTurn !== undefined && actualTurn?.actions.length === 1 && !checkIfActionIsDone(actualTurn?.actions.at(0))) {
                    updateTurn(previousTurn, ResponseType.REFRESH_TURN, null)
                } else if (previousTurn !== undefined && actualTurn !== undefined) {
                    previousTurn = actualTurn
                    updateTurn(previousTurn, ResponseType.REFRESH_TURN, actualTurn)
                }
            } catch (error) {
                setIsError(true);
            }
        }

        function checkIfActionIsDone(action: ActionInfo | undefined) {
            if (action === undefined) return false
            if (action.fromField !== null) return false
            if (action.toField !== null) return false
            return action.buildActionType === null;
        }

        getData();
    }

    function addTurn(turn: TurnInfo) {
        setFetchedTurns(oldArray => [...oldArray, turn]);
    }

    function setTurns(turns: TurnInfo[]) {
        setFetchedTurns(turns)
    }
    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            try {
                const {data} = await axios.get<TurnInfo[]>(url);
                setFetchedTurns(data);
                let previousTurn = data.sort((a, b) => a.id - b.id).reverse().at(1)
                let actualTurn = data.sort((a, b) => a.id - b.id).reverse().at(0)
                if (previousTurn === undefined) {
                    previousTurn = actualTurn
                }
                if (previousTurn !== undefined && actualTurn?.actions.length === 1 && !checkIfActionIsDone(actualTurn?.actions.at(0))) {
                    updateTurn(previousTurn, ResponseType.REFRESH_TURN, null)
                } else if (previousTurn !== undefined && actualTurn !== undefined) {
                    previousTurn = actualTurn
                    updateTurn(previousTurn, ResponseType.REFRESH_TURN, actualTurn)
                }
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        }

        function checkIfActionIsDone(action: ActionInfo | undefined) {
            if (action === undefined) return false
            if (action.fromField !== null) return false
            if (action.toField !== null) return false
            return action.buildActionType === null;
        }

        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return {
        loading,
        error,
        addTurn,
        data: turns,
        setTurns,
        refetch,
    };
}
