import BuilderInfo from "./BuilderInfo";
import PlayerInfo from "./PlayerInfo";
import FieldInfo from "./FieldInfo";
import UserInfo, {defaultUserInfo} from "./UserInfo";

export default interface GameInfo {
    id: number
    turnDurationInSeconds: number
    startedOn: Date | null
    endedOn: Date | null
    turnStartedOn: Date | null
    host: UserInfo
    winner: PlayerInfo | null
    linkToken: string
    builders: BuilderInfo[]
    players: PlayerInfo[]
    fields: FieldInfo[]
    currentTurn: PlayerInfo | null,
    playerId: number
}

export const defaultGameInfo = {
    id: 0,
    turnStartedOn: null,
    turnDurationInSeconds: 7,
    builders: [],
    players: [],
    fields: [],
    winner: null,
    playerId: -1,
    currentTurn: null,
    linkToken: "",
    startedOn: null,
    endedOn: null,
    host: defaultUserInfo()
}

export function getGameName(game: GameInfo) {
    let botCount = 1
    return game.players.map(e => e.user?.nickname ?? `Bot${botCount++}`).join(" vs ")
}
