import UserInfo, {defaultUserInfo} from "./UserInfo";

export interface UserStats {
    user: UserInfo
    gamesWon: number
}

export function defaultUserStats() : UserStats {
    return {
        user: defaultUserInfo(),
        gamesWon: 0
    }
}
