import {Button} from "@mui/material";
import HostGameDialog from "./HostGameDialog";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function HostGameButton() {
    const [openHostPanel, setOpenHostPanel] = useState(false)
    const {t} = useTranslation()

    return <>
        <Button variant={"contained"} onClick={() => setOpenHostPanel(true)} sx={{my: 1}} fullWidth>{t("home.joinGame.hostAGame")}</Button>
        <HostGameDialog open={openHostPanel} handleClose={() => setOpenHostPanel(false)}/>
    </>
}
