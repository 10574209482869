import PlayerDetail from "./PlayerDetail";
import PlayerInfo from "../../../types/PlayerInfo";
import TurnInfo from "../../../types/TurnInfo";

interface PlayersProps {
    players: PlayerInfo[]
    playerId: number
    currentTurn: TurnInfo | undefined
}

export default function Players({players, currentTurn}: PlayersProps) {

    return (<>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
            {players.map((player) => (
                <PlayerDetail key={player.user?.id} playerId={player.user?.id ?? 0} url={player.user?.avatarUrl ?? ""}
                              name={player.user?.nickname ?? ""} god={player.god} color={player.color}
                              hasTurn={currentTurn !== undefined ? currentTurn.player.id === player.id : false}/>
            ))
            }
        </div>
    </>)
}
