import {Button} from "@mui/material";
import {WestSharp} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useGame} from "../../../pages/GamePage";
import {useTranslation} from "react-i18next";

export default function LeaveGameButton() {
    const navigate = useNavigate()
    const gameContext = useGame()
    const notification = useSnackbar()
    const {t} = useTranslation()

    async function handleClick() {
        try {
            await gameContext.handleLeaveGame()
            notification.enqueueSnackbar(t("lobby.leaveGame.successMsg"), {variant: "success"})
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
            notification.enqueueSnackbar(t("lobby.leaveGame.warningMsg"), {variant: "warning"})
        } finally {
            navigate("/")
        }
    }

    return <Button onClick={handleClick} startIcon={<WestSharp/>}>{t("lobby.leaveGame.btnText")}</Button>
}
