import {defaultUserStats, UserStats} from "../../types/UserStats";
import {Skeleton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import useGetSingle from "../useGetObject";
import useAuth from "../useAuth";

export default function Leaderboard({rows}:{rows:UserStats[]}) {
    const auth = useAuth()
    const {data, error, loading} = useGetSingle<UserStats>("/game/stats/user", defaultUserStats())

    if(loading || error) return <LeaderBoardFixed rows={rows} />

    if(!(rows.findIndex(e => e.user.id === auth.user?.id) >= 0)) rows.push(data)
    return <LeaderBoardFixed rows={rows} />
}

function LeaderBoardFixed({rows}:{rows:UserStats[]}) {
    let counter = 1
    const {t} = useTranslation()

    return <Table size="small" sx={{mt:2, maxHeight: "60vh"}}>
        <TableHead>
            <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("leaderboard.nickname")}</TableCell>
                <TableCell>{t("leaderboard.wins")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map(row => <TableRow key={counter}>
                <TableCell>{counter++}</TableCell>
                <TableCell>{row.user.nickname}</TableCell>
                <TableCell>{row.gamesWon}</TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}


export function LoadingLeaderboard() {
    const {t} = useTranslation()
    const rows = new Array(10)

    return <Table size="small" sx={{mt:2}}>
        <TableHead>
            <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("leaderboard.nickname")}</TableCell>
                <TableCell>{t("leaderboard.wins")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map(() => <TableRow>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}
