import {Button} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface ButtonUtilProps {
    buttonType:string
    sendFriendRequest:() => Promise<void>|null
}

export default function ButtonUtil({buttonType,sendFriendRequest}:ButtonUtilProps){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const FriendButton = ()=>{
        switch (buttonType) {
            case "User": return <Button onClick={() => navigate("/profile")} variant={"contained"} sx={{ marginLeft: "auto", marginRight: "auto", display: "block"}} size="small">{t("friends.editprofile")}</Button>;
            case "SentRequest": return  <Button disabled={true} variant={"contained"} sx={{ marginLeft: "auto", marginRight: "auto", display: "block"}} size="small">{t("friends.requestsend")}</Button>;
            case "Requested": return <Button disabled={true} variant={"contained"} sx={{ marginLeft: "auto", marginRight: "auto", display: "block"}} size="small">{t("friends.checkrequests")}</Button>;
            default : return <Button onClick={sendFriendRequest}  variant={"contained"} sx={{ marginLeft: "auto", marginRight: "auto", display: "block"}} size="small">{t("friends.sendrequest")}</Button> ;
        }
    }
    return(
        <div>
         <FriendButton />
        </div>
    )
}
