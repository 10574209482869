import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import "./FriendsPage.scss"
import Background from "../components/particles/Background";
import Conditional from "../components/particles/friends/Conditional";
import {useTranslation} from "react-i18next";
import ScalableSideInfo from "../components/particles/game/ScalableSideInfo";

let keyCounter = 0
export default function FriendsPage() {
    const {t} = useTranslation();
    const [page, setPage] = useState(t("friends.friends"));
    const [clickedButton, setClickedButton] = useState(t("friends.friends"));
    const buttons = [t("friends.friends"), t("friends.requests"), t("friends.searchFriend")];

    function handleOnclick(button: string) {
        setClickedButton(button);
        setPage(button);
    }

    const buttonUtil = buttons.map(button => {
        if (button === clickedButton) {
            return <Button key={keyCounter++} style={{margin: 2, backgroundColor: "white", color: "#003D92"}}
                           variant={"contained"} onClick={() => handleOnclick(button)}>{button}</Button>
        } else {
            return <Button key={keyCounter++} style={{margin: 2}} variant={"contained"}
                           onClick={() => handleOnclick(button)}>{button}</Button>
        }
    });
    return (
        <Background>
            <ScalableSideInfo isForGame={false}/>
            <Typography variant={"h1"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}
                        sx={{textAlign: "center"}}>Santorini</Typography>
            <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"} fontWeight={"bold"}
                        sx={{textAlign: "center"}}>{t("friends.friends")}</Typography>
            {buttonUtil}
            <Conditional item={page}/>
        </Background>
    )
}
