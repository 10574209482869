import {useGetCollection} from "../useGetObject";
import {UserStats} from "../../types/UserStats";
import {useSnackbar} from "notistack";
import React, {useEffect} from "react";
import Leaderboard, {LoadingLeaderboard} from "./Leaderboard";

export function WorldLeaderboard() {
    const {data, loading, error, errorMsg} = useGetCollection<UserStats>("/game/stats/world")
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg, {variant:"error"})
    }, [error, errorMsg, enqueueSnackbar])

    if (loading) return <LoadingLeaderboard/>

    return <Leaderboard rows={data}/>
}

export function FriendsLeaderboard() {
    const {data, loading, error, errorMsg} = useGetCollection<UserStats>("/game/stats/friends")
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg, {variant:"error"})
    }, [error, errorMsg, enqueueSnackbar])

    if (loading) return <LoadingLeaderboard/>

    return <Leaderboard rows={data}/>
}

export function RecentWorldLeaderboard() {
    const {data, loading, error, errorMsg} = useGetCollection<UserStats>("/game/stats/world/recent")
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg, {variant:"error"})
    }, [error, errorMsg, enqueueSnackbar])

    if (loading) return <LoadingLeaderboard/>

    return <Leaderboard rows={data}/>
}
