export function minutesBetween(fromDate:Date|null, untilDate:Date|null) : number {
    if(fromDate === null || untilDate === null) return 0
    var diffMs = calculateDiffMs(fromDate, untilDate)
    // minutes
    return Math.round(((diffMs % 86400000) % 3600000) / 60000)
}

export function secondsBetween(fromDate:Date, untilDate:Date) {
    return calculateDiffMs(fromDate, untilDate)/1000 // milliseconds between now & then
}

function calculateDiffMs(fromDate:Date, untilDate:Date) {
    return (new Date(untilDate).getTime() - new Date(fromDate).getTime()) // milliseconds between now & then
}

export function datePlusMs(date:Date, ms:number) {
    return new Date(new Date(date).getTime()+ms)
}