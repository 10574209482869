import GameInvite from "../../../types/GameInvite";
import {Avatar, IconButton, Paper, Typography} from "@mui/material";
import {getGameName} from "../../../types/GameInfo";
import "./List.scss"
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {putObject} from "../../../functions/objectService";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

export default function InvitesList({invites, refresh}: { invites: GameInvite[], refresh: () => void }) {

    if(invites.length === 0) return <div>
        Oops, you don't seem to have any invites!
    </div>

    return <div className={"inviteList"}>
        {invites.map(e => <InviteCard invite={e} key={e.id} refresh={refresh} />)}
    </div>
}

function InviteCard({invite, refresh}: { invite: GameInvite, refresh:() => void }) {
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    async function handleReject() {
        try {
            await putObject("/game/invites/respond", {
                gameInviteId: invite.id,
                accepting: false
            })
            enqueueSnackbar("Rejected invite", {variant: "success"})
            await refresh()
        } catch (e:any) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function handleAccept() {
        try {
            await putObject("/game/invites/respond", {
                gameInviteId: invite.id,
                accepting: true
            })
            enqueueSnackbar("Accepted invite", {variant: "success"})
            navigate(`/game/${invite.game.linkToken}`)
        } catch (e:any) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Paper className={"inviteCard flexElements"}>
        <div className={"inviteAvatar"}>
            <Avatar src={invite.sender.avatarUrl} />
        </div>
        <div style={{width: "max-content"}}>
            <Typography variant={"h5"}>{getGameName(invite.game)}</Typography>
            <div className={"flexElements spaceBetweenElements"}>
                <IconButton onClick={handleReject} color={"error"}>
                    <CloseIcon />
                </IconButton>
                <IconButton onClick={handleAccept} color={"success"}>
                    <CheckIcon />
                </IconButton>
            </div>
        </div>
    </Paper>
}