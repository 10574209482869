export default interface UserInfo {
    id: number
    nickname: string
    avatarUrl: string
}

export function defaultUserInfo(): UserInfo {
    return {
        id: 0,
        nickname: "",
        avatarUrl: ""
    }
}