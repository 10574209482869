import {Avatar, Box, Card, Grid, Typography} from "@mui/material";
import React from "react";
import Achievement from "../../../types/Achievement";
import {useGetCollection} from "../../../hooks/useGetObject";
import useAuth from "../../../hooks/useAuth";
import ReturnRightCard from "../profileCard/ReturnRightCard";
import {CurrentUser} from "../../../types/User";

export function ReturnRightAchievementType({achievement,user,name}:{achievement:Achievement,user:CurrentUser|null,name:string}) {
    switch (achievement.achievementType) {
        case 'Card':
            return <div style={{width: 200, display: "block", marginLeft: "auto", marginRight: "auto"}}>
                <ReturnRightCard rewardTokens={0} buttonFunction2={()=>null} buttonFunction={()=>null} buttonName={""} userRelationShip={""} avatar={user?.avatar} name={name} nickname={user?.nick}/>
            </div>;
        default:
            return <Avatar sx={{
                height: 250,
                width: 250,
                marginTop: 2,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block"
            }} src={achievement.urlDesc}/>;
    }
}

export function StoreCard({achievement}: { achievement:Achievement }) {
    const auth= useAuth();
   return( <Card sx={{height:350}}>
       <ReturnRightAchievementType achievement={achievement} user={auth.user} name={achievement.name}/>
        <Box component={"div"} sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 200,
            marginLeft: "auto",
            marginRight: "auto",
            padding: 2
        }}>
            <Typography variant={"h4"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                        fontWeight={"bold"}
                        sx={{textAlign: "center"}}>{achievement.name}</Typography>
        </Box>
    </Card>)
}

export function StoreCardList({url}:{url:string}) {
    const {
        data: achievements
    } = useGetCollection<Achievement>(url);

    return(
        <Grid container sx={{marginTop:10,padding:"5%" ,paddingBottom:"15%"}}  spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {achievements.map(achievement =>{
                return (<Grid key={achievement.id} item xs={12} sm={4} md={4}>
                    <StoreCard achievement={achievement}/>
                </Grid>)
            })}
        </Grid>
    )

}
