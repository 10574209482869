import FieldInfo from "../types/FieldInfo";
import GameInfo from "../types/GameInfo";
import BuilderInfo from "../types/BuilderInfo";
import ActionInfo from "../types/ActionInfo";
import TurnInfo from "../types/TurnInfo";
import {ActionType} from "../types/ActionType";
import {GodAbility} from "../types/GodAbility";

export function findFieldByBuilderId(builderId: number, game: GameInfo) {
    return game.fields.find(field => field.builder?.id === builderId)
}

export function findFieldByXAndY(fields: FieldInfo[], x: number, y: number): FieldInfo | undefined {
    return fields.find(field => field.x === x && field.y === y)
}

export function findBuilderById(builderId: number, game: GameInfo): BuilderInfo | undefined {
    return game.builders.find(builder => builder.id === builderId)
}

export function hasGameStarted(game: GameInfo) {
    if(game.players === undefined) return false
    return game.players.length > 1 && game.players.length < 5 && game.startedOn !== null
}

export function hasGameBeenWon(game: GameInfo) {
    return game.winner !== null
}

export function isAllowedGodAbility(godAbility: GodAbility) {
    return godAbility === GodAbility.EXTRA_MOVE_NOT_BACK || godAbility === GodAbility.EXTRA_BUILD_NOT_SAME || godAbility === GodAbility.EXTRA_BUILD_ONLY_SAME
}

export function getLastMoveAction(turn: TurnInfo) {
    return turn.actions.filter(action => action.type === "MOVE" && action.toField !== null).sort((a, b) => a.id - b.id).reverse().at(0)
}

export function validatePawnClick(game: GameInfo, currentAction: ActionInfo | undefined, builderId: number | undefined, currentTurn: TurnInfo | undefined) {
    if (currentAction === undefined) return false
    if (currentAction.type !== ActionType.MOVE) return false
    if (currentAction.buildActionType !== null) return false
    if (builderId !== undefined) return false
    return currentTurn?.player.id === game.playerId;
}


export function playerWentUp(fromField: FieldInfo, toField: FieldInfo): boolean {
    return toField.towerSize - fromField.towerSize === 1
}

// export function checkIfCanGoUp(turns: TurnInfo[]): boolean {
//     let lastTurns = getLastTurnsFromOtherPlayers(turns)
//     let playerCanGoUp = true
//     lastTurns.forEach(turn => {
//         if ((turn.player.god?.ability ?? GodAbility.NONE) === GodAbility.OPPONENT_NOT_UP) {
//             let moveAction = getLastActionOfType(turn.actions, ActionType.MOVE)
//             if (moveAction !== undefined) {
//                 if (playerWentUp(moveAction.fromField, moveAction.toField)) {
//                     playerCanGoUp = false
//                 }
//             }
//         }
//     })
//     return playerCanGoUp
// }


export function getZByTowerSize(towerSize: number) {
    switch (towerSize) {
        case 3:
            return 2.168
        case 2:
            return 1.6
        case 1:
            return 0.87
        default:
            return 0
    }
}

