import Participant from "./Participant";
import LobbySection from "./LobbySection";
import PlayerInfo from "../../../types/PlayerInfo";
import {useTranslation} from "react-i18next";

export default function Participants({players}: { players: PlayerInfo[] }) {
    const {t} = useTranslation()

    return <LobbySection title={t("lobby.participants.title")}>
        {players.map(e => <Participant key={e.id} player={e}/>)}
    </LobbySection>
}
