import useAuth from "../hooks/useAuth";
import React, {useState} from "react";
import Background from "../components/particles/Background";
import ReturnRightCard from "../components/particles/profileCard/ReturnRightCard";
import {Avatar, Button, FormLabel, Typography} from "@mui/material";
import {useGetCollection} from "../hooks/useGetObject";
import Achievement from "../types/Achievement";
import GameTitle from "../components/particles/game/GameTitle";
import {Box} from "@mui/system";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {putObjectWithResponse} from "../functions/objectService";
import {useTranslation} from "react-i18next";

let keyCounter = 0
export default function EditCardPage() {
    const auth = useAuth();
    const {t} = useTranslation();
    const notification = useSnackbar();
    const navigate = useNavigate();
    const [cardLayout, setCardLayout] = useState({
        cardStyle: auth.user?.card,
        cardAvatar: auth.user?.avatar
    });
    const {
        data: avatars
    } = useGetCollection<Achievement>("/achievement/allBoughtAvatars");
    const {
        data: cards
    } = useGetCollection<Achievement>("/achievement/allBoughtCards");

    function handleChange(attribute: string, value: string) {
        setCardLayout({...cardLayout, [attribute]: value})
    }

    async function onSubmit() {
        try {
            var response = await putObjectWithResponse<{ cardStyle: string | undefined, cardAvatar: string | undefined }, string>("/auth/updateCardLayout", cardLayout);
            notification.enqueueSnackbar(`Card layout updated!`, {variant: "success"})
            auth.setToken(response.data);
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to update your account.", {variant: "error"});
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    const avatarList = avatars.map(avatar => {
        if (avatar.urlDesc === cardLayout.cardAvatar) {
            return (<div key={keyCounter++} style={{padding: 5}} onClick={() => handleChange("cardAvatar", avatar.urlDesc)}>
                <Avatar style={{width: 50, height: 50, border: "3px solid blue"}} src={avatar.urlDesc}/>
            </div>)
        } else {
            return (<div key={keyCounter++} style={{padding: 5}} onClick={() => handleChange("cardAvatar", avatar.urlDesc)}>
                <Avatar style={{width: 50, height: 50}} src={avatar.urlDesc}/>
            </div>)
        }
    });

    const cardList = cards.map(card => {
        if (card.name === cardLayout.cardStyle) {
            return (<div key={keyCounter++} style={{padding: 5, width: 100, border: "3px solid blue", borderRadius: 25}}
                         onClick={() => handleChange("cardStyle", card.name)}>
                <ReturnRightCard rewardTokens={0} buttonFunction2={() => null} buttonFunction={() => null}
                                 buttonName={""} userRelationShip={""} avatar={cardLayout.cardAvatar}
                                 nickname={auth.user?.nick} name={card.name}/>
            </div>)
        } else {
            return (<div key={keyCounter++} style={{padding: 5}} onClick={() => handleChange("cardStyle", card.name)}>
                <ReturnRightCard rewardTokens={0} buttonFunction2={() => null} buttonFunction={() => null}
                                 buttonName={""} userRelationShip={""} avatar={cardLayout.cardAvatar}
                                 nickname={auth.user?.nick} name={card.name}/>
            </div>)
        }
    });

    return (
        <Background>
            <div className={"flexElements centerElements verticalAlignCenter"}>
                <Box
                    component="div"
                    sx={{maxWidth: 1000, marginBottom: 20}}
                >
                    <GameTitle/>
                    <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                                fontWeight={"bold"}
                                sx={{textAlign: "center"}}>{t("cardlayout.changecard")}</Typography>
                    <div style={{width: 200, display: "block", marginLeft: "auto", marginRight: "auto"}}>
                        <ReturnRightCard rewardTokens={0} buttonFunction2={() => null} buttonFunction={() => null}
                                         buttonName={""} userRelationShip={""} avatar={cardLayout.cardAvatar}
                                         name={cardLayout.cardStyle} nickname={auth.user?.nick}/>
                    </div>
                    <FormLabel>{t("cardlayout.availablecards")}</FormLabel>
                    <div style={{display: "flex"}}>
                        {cardList}
                    </div>
                    <FormLabel>{t("cardlayout.availableavatars")}</FormLabel>
                    <div style={{display: "flex"}}>
                        {avatarList}
                    </div>
                    <Button sx={{m: 1}} onClick={onSubmit} variant="contained">{t("editprofile.change")}</Button>
                    <Button sx={{m: 1}} onClick={() => navigate("/profile/preferences")}
                            variant="contained">{t("editprofile.preferences")}</Button>
                    <Button sx={{m: 1}} onClick={() => navigate("/profile")}
                            variant="contained">{t("editprofile.editprofile")}</Button>
                    <Button sx={{m: 1}} onClick={() => navigate("/profile/AwardTokens")}
                            variant="contained">{t("editprofile.awardtokens")}</Button>
                    <Button sx={{m: 1}} onClick={() => auth.setToken("")}
                            variant="contained">{t("editprofile.logout")}</Button>
                </Box>
            </div>
        </Background>
    )


}
