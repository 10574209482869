import {Button, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system"
import {useNavigate} from "react-router-dom";
import {postObjectWithResponseWithHeader, setAuthHeader} from "../functions/objectService";
import {useSnackbar} from "notistack";
import React, {useEffect} from "react";
import useAuth from "../hooks/useAuth";
import GameTitle from "../components/particles/game/GameTitle";
import {putToken} from "../functions/tokenService";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

interface ILogin {
    username: string;
    password: string;
}

function LoginPage() {
    const auth = useAuth();
    const {t} = useTranslation()
    const {register, handleSubmit} = useForm<ILogin>();
    const onSubmit: SubmitHandler<ILogin> = data => SubmitData(data);
    const notification = useSnackbar();
    const navigate = useNavigate();
    useEffect(() => {
        putToken(undefined)
        setAuthHeader()
    })

    async function SubmitData(data: ILogin) {
        try {
            let response = await postObjectWithResponseWithHeader<{ username: string, password: string }, string>("/auth/login", data, {})
            if (response.data === "") {
                notification.enqueueSnackbar(`Account not enabled. Check your mail!`, {variant: "warning"})
            } else {
                notification.enqueueSnackbar(`Logged in!`, {variant: "success"})
                auth.setToken(response.data)
                setAuthHeader()
                navigate("/")
            }
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to log you in. Is the user not enabled", {variant: "error"});
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <>
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Box
                component="form"
                noValidate
                sx={{maxWidth: 400}}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <GameTitle/>
                <Typography variant={"h2"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("login.login")}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    label={t("login.email")}
                    variant="filled"
                    {...register("username")}
                />
                <TextField
                    fullWidth
                    type={"password"}
                    margin={"dense"}
                    label={t("login.password")}
                    variant="filled"
                    {...register("password")}/>
                <Button id={"submitLogin"} type="submit" sx={{m: 1}} variant="contained">{t("login.login")}</Button>
                <Button sx={{m: 1}} variant="contained"
                        onClick={() => navigate("/Register")}>{t("login.registerhere")}</Button>
                <Button variant="text" sx={{m: 1}}
                        onClick={() => navigate("/SendResetMail")}>{t("login.forgotpassword")}</Button>
            </Box>
        </div>
    </>
}

export default LoginPage
