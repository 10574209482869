import React, {Suspense} from "react";
import {Canvas} from "@react-three/fiber";
import {OrbitControls} from "@react-three/drei";
import RotatingIsland from "./RotatingIsland";

export default function Background(props: { children?: React.ReactNode }) {
    return (<>
        <div className={"sky"} style={{zIndex: -1, position: "absolute"}}>
            <Suspense>
                <Canvas camera={{position: [5, 2, 2]}} className={"canvas"}>
                    <ambientLight/>
                    <pointLight position={[0, 5, 5]} intensity={0.4}/>
                    <pointLight position={[0, 5, 0]} intensity={0.4}/>
                    {/*<gridHelper args={[5, 5, "white", "white"]}/>*/}
                    <OrbitControls minZoom={0.1}
                                   enablePan={false}
                                   enableRotate={false}
                                   maxPolarAngle={0.9}
                                   minPolarAngle={0.9}
                                   minDistance={12}
                                   maxDistance={12}
                    />
                    <RotatingIsland/>
                </Canvas>
            </Suspense>
        </div>
        {props.children !== null && props.children}
    </>)
}
