import useGetSingle, {useGetCollection} from "../useGetObject";
import GameInfo, {getGameName} from "../../types/GameInfo";
import {Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Loading} from "../../components/particles/Loading";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import useAuth from "../useAuth";
import {minutesBetween} from "../../functions/date";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ClearIcon from '@mui/icons-material/Clear';

const usingRows = 5
export default function MyGames() {
    const [page, setPage] = useState(1)
    const {data, error, loading, errorMsg} = useGetCollection<GameInfo>(`/game/stats/played/${page-1}/${usingRows}`)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg)
    }, [error, errorMsg, enqueueSnackbar])

    if (loading) return <Loading/>

    return <>
        <GamesTable games={data} />
        <GamesPlayedPagination page={page} setPage={setPage} />
    </>
}

function GamesTable({games}:{games:GameInfo[]}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("leaderboard.name")}</TableCell>
                <TableCell>{t("game.winnerPage.gameDuration")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {games.map(game => <TableRow key={game.id} onClick={() => navigate(`/game/${game.linkToken}`)} sx={{cursor: "pointer"}}>
                <TableCell>
                    <GameStatusChip game={game} />
                </TableCell>
                <TableCell>{getGameName(game)}</TableCell>
                <TableCell align={"center"}>{minutesBetween(game.startedOn, game.endedOn)} {t("game.winnerPage.minutes")}</TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}

function GamesPlayedPagination({page, setPage}: {page:number, setPage:(e:number) => void}) {
    const {data : gamesPlayed, error, loading, errorMsg} = useGetSingle<number>("/game/stats/played/count", 0)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (error) enqueueSnackbar(errorMsg)
    }, [error, errorMsg, enqueueSnackbar])

    if(loading || gamesPlayed <= usingRows) return <></>

    const calculatedUnsafe = gamesPlayed/usingRows
    const calculated = Math.trunc((Math.trunc(calculatedUnsafe)+"").length === (calculatedUnsafe+"").length ? calculatedUnsafe : (calculatedUnsafe+1))

    return <div className={"flexElements centerElements"}>
        <Stack spacing={2}>
        <Pagination sx={{mt:2}} defaultValue={page} onChange={(e,v) => setPage(v)} count={calculated} variant="outlined"/>
    </Stack>
    </div>
}

function GameStatusChip({game}:{game:GameInfo}) {
    const {user} = useAuth()

    if(game.winner === null) return <AutorenewIcon color={"warning"} />

    function wonGame(game:GameInfo) {
        return game.winner?.user?.id === user?.id
    }

    if(wonGame(game)) return <EmojiEventsIcon color={"success"} />
    return <ClearIcon color={"error"} />
}
