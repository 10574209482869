import React, {createContext, useContext, useEffect} from "react";
import usePreference, {Preference, UsePreferenceProps} from "./usePreference";
import {useTranslation} from "react-i18next";

function defaultPreferenceProps() : UsePreferenceProps {
    return {
        value: true,
        set: () => {},
        toggle: () => {}
    }
}

const PreferenceContext = createContext<{
    SoundEffects: UsePreferenceProps
    NotificationsOnTurnStart: UsePreferenceProps
    UseDutch: UsePreferenceProps
}>({
    SoundEffects: defaultPreferenceProps(),
    NotificationsOnTurnStart: defaultPreferenceProps(),
    UseDutch: defaultPreferenceProps()
})

export default function usePreferences() {
    return useContext(PreferenceContext)
}

export function PreferenceProvider(props:{children:React.ReactNode}) {
    const SoundEffects = usePreference(Preference.SoundEffects)
    const NotificationsOnTurnStart = usePreference(Preference.NotificationsOnTurnStart)
    const UseDutch = usePreference(Preference.UseDutch)
    const {i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(UseDutch.value ? "nl" : "en")
    }, [UseDutch.value, i18n])

    return <PreferenceContext.Provider value={{
        SoundEffects,
        NotificationsOnTurnStart,
        UseDutch,
    }}>
        {props.children}
    </PreferenceContext.Provider>
}
