import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import useGameInvite from "../../../hooks/useGameInvite";
import {useParams} from "react-router-dom";

export default function JoinGame() {
    const {inviteToken} = useParams<{inviteToken:string}>()
    const snackbar = useSnackbar()
    const [token, setToken] = useState(inviteToken ?? "")
    const {t} = useTranslation()
    const joinGame = useGameInvite()

    async function onSubmit() {
        try {
            if(token.length === 0) return;
            await joinGame(token)
        } catch (e) {
            snackbar.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <div className={"flexElements verticalAlignCenter centerElements"}>
        <div style={{background: "white", borderRadius: 5}}>
            <TextField onChange={(e) => setToken(e.target.value)} value={token} size={"small"}
                       label={t("home.joinGame.insertToken")}/>
        </div>
        <Button id={"joinGameBtn"} variant={"contained"} onClick={onSubmit} sx={{height: "100%", ml: 1}}>{t("home.joinGame.join")}</Button>
    </div>
}
