import React from "react";
import {Typography} from "@mui/material";

export default function LobbySection(props: { children: React.ReactNode, title: string }) {
    return <div style={{marginTop: 20, textAlign: "center"}}>
        <Typography variant={"subtitle1"} color={"text.secondary"}>{props.title}</Typography>
        <div className={"flexElements centerElements"} style={{maxWidth: 400}}>
            {props.children}
        </div>
    </div>
}
