import {useGetCollection} from "../hooks/useGetObject";
import {Paper, Typography} from "@mui/material";
import InvitesList from "../components/particles/invites/List";
import GameInvite from "../types/GameInvite";
import {Loading} from "../components/particles/Loading";
import useInterval from "../hooks/useInterval";

export default function InvitesPage() {
    const request = useGetCollection<GameInvite>("/game/invites/get")
    useInterval(request.refresh, request.data.length + 3)

    return <div className={"flexElements centerElements"}>
        <Paper sx={{p: 3, m: 2}}>
            <Typography variant="h3">
                Game invites
            </Typography>
            {
                (request.loading && !request.hasLoaded)
                    ? <Loading/>
                    : <InvitesList invites={request.data} refresh={request.refresh}/>
            }
        </Paper>
    </div>
}
