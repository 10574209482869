import GameInfo from "./GameInfo";
import UserInfo from "./UserInfo";

export default interface GameInvite {
    id: number
    sender: UserInfo
    receiver: UserInfo
    game: GameInfo
    status: InviteStatus
}

export enum InviteStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Rejected = "Rejected",
    Revoked = "Revoked"
}
