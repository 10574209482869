import {Divider, LinearProgress, Paper, Typography, useMediaQuery} from "@mui/material";
import useLiveGameData from "../../../hooks/useLiveGameData";
import GameInfo, {getGameName} from "../../../types/GameInfo";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {useEffect, useState} from "react";
import {datePlusMs, secondsBetween} from "../../../functions/date";
import useInterval from "../../../hooks/useInterval";

export default function LiveGameData() {
    const isSmallScreen = useMediaQuery("(min-width: 1000px)")
    const [panelOpen, setOpenPanel] = useState(isSmallScreen)
    const liveGameData = useLiveGameData()
    let counter = 0

    useEffect(() => {
        setOpenPanel(isSmallScreen)
    }, [isSmallScreen])

    if (liveGameData.games.length === 0) return <></>

    return <Paper sx={{p: 2, m: 1, position: "absolute", top: 6, right: 6, minWidth: 200}}>
        <Typography>
            Live game info <span onClick={() => setOpenPanel(!panelOpen)}>
                {panelOpen ? "(open)" : "(close)"}
            </span>
        </Typography>
        <Divider/>
        <div style={{display: panelOpen ? "block" : "none", maxHeight: "50vh", overflowY: "auto"}}>
            {liveGameData.games.map(game => <LiveGameRow key={game.id} gameCount={++counter} game={game}/>)}
        </div>
    </Paper>
}

function LiveGameRow({game, gameCount}: { game: GameInfo, gameCount: number }) {
    const navigate = useNavigate()
    const {user} = useAuth()
    const [progress, setProgress] = useState(0)
    useInterval(checkProgress, 0.1)

    function checkProgress() {
        if (game.turnStartedOn !== null && game.winner === null) {
            const dateNow = new Date()
            const dateTurnEnds = datePlusMs(game.turnStartedOn, game.turnDurationInSeconds * 1000)
            const timeLeft = secondsBetween(dateNow, dateTurnEnds)
            //console.log(`Time left: ${timeLeft}, now: ${dateNow.getMinutes()}:${dateNow.getSeconds()}, ends at: ${dateTurnEnds.getMinutes()}:${dateTurnEnds.getSeconds()}`)
            if (timeLeft < 0) setProgress(100)
            else setProgress(100 - (timeLeft / game.turnDurationInSeconds) * 100)
        }
    }

    return <div onClick={() => navigate(`/game/${game.linkToken}`)} style={{cursor: "pointer"}}>
        <Typography variant={"subtitle1"}>{`${gameCount}. ${getGameName(game)}`}</Typography>
        {game.currentTurn?.user !== undefined && <>
            <Typography variant={"subtitle2"}>
                {"Now playing: "}
                <span style={{color: user?.id === game.currentTurn?.user?.id ? "red" : "inherit"}}>
                    {game.currentTurn?.user?.nickname}
                </span>
            </Typography>
            {progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress}/>}
        </>}
    </div>
}
