import {Box} from "@mui/material";
import React from "react";
import useAuth from "../../../hooks/useAuth";
import ReturnRightCard from "../profileCard/ReturnRightCard";
export default function ProfileIcon(){
    const auth = useAuth();
    return <>
        <Box component="div" sx={{width:150,alignContent:"right",position: "absolute",right:5,top:2}}>
            <ReturnRightCard rewardTokens={auth.user?.rewardtokens} userRelationShip={""} buttonName={"profile"} buttonFunction={()=>null} buttonFunction2={()=>null} name={auth.user?.card} nickname={auth.user?.nick} avatar={auth.user?.avatar}/>
        </Box>
    </>
}
