import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {postObjectWithResponse} from "../../../functions/objectService";
import Game from "../../../types/Game";
import {useTranslation} from "react-i18next";

export default function HostGameDialog({open, handleClose}: { open: boolean, handleClose: () => void }) {
    const notification = useSnackbar()
    const [turnDuration, setTurnDuration] = useState(10)
    const navigate = useNavigate()
    const {t} = useTranslation()

    async function handleSubmit() {
        try {
            const response = await postObjectWithResponse<{ turnDurationInSeconds: number }, Game>("/game/create", {turnDurationInSeconds: turnDuration})
            const game = response.data
            notification.enqueueSnackbar(t("home.hostGame.createdAGame"), {variant: "success"})
            navigate(`/game/${game.linkToken}`)
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    function handleChange(newNumber: string) {
        if (Number.isNaN(newNumber)) {
            return;
        }
        let value = Number.parseInt(newNumber)
        if (value < 7) {
            notification.enqueueSnackbar(t("home.hostGame.turnDurationMinError"), {variant: "error"})
            return;
        }
        if (value > 30) {
            notification.enqueueSnackbar(t("home.hostGame.turnDurationMaxError"), {variant: "error"})
            return;
        }
        setTurnDuration(value)
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Be the host!
        </DialogTitle>
        <DialogContent>
            <TextField fullWidth margin={"dense"} label={t("home.hostGame.turnDurationLabel")} type={"number"} value={turnDuration}
                       onChange={(e) => handleChange(e.target.value)}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t("home.hostGame.cancel")}</Button>
            <Button onClick={handleSubmit} autoFocus>
                {t("home.hostGame.host")}
            </Button>
        </DialogActions>
    </Dialog>
}
