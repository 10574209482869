import {Avatar, Button, Typography} from "@mui/material";
import defaultProfilePicture from "../../../media/img/defaultProfilePicture.jpg";
import {useState} from "react";
import {useSnackbar} from "notistack";
import User from "../../../types/User";
import {useTranslation} from "react-i18next";
import {useGame} from "../../../pages/GamePage";

export default function Friend({user, gameId}: { user: User, gameId: number | string }) {
    const notification = useSnackbar()
    const gameContext = useGame()
    const [sentEmail, setSentEmail] = useState(false)
    const {t} = useTranslation()

    async function handleInvite() {
        try {
            if (sentEmail) return;
            await gameContext.inviteUser(user.id)
            notification.enqueueSnackbar(t("lobby.friends.successfullyInvited", {name: user.nickname}), {variant: "success"})
            setSentEmail(true)
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Button sx={{m: 1}} onClick={handleInvite} color={sentEmail ? "success" : "warning"} startIcon={
        <Avatar sx={{width: 30, height: 30, background: "Green"}}
                src={user.avatarUrl ?? defaultProfilePicture}>
            {user.id}
        </Avatar>}
                   className={"flexElements"}>
        <Typography sx={{textAlign: "center"}} className={"flexElements verticalAlignCenter"}>
            {user.nickname}
        </Typography>
    </Button>
}
