import {Avatar, Typography} from "@mui/material";
import defaultProfilePicture from "./../../../media/img/defaultProfilePicture.jpg"
import CloseIcon from '@mui/icons-material/Close';
import {useSnackbar} from "notistack";
import PlayerInfo from "../../../types/PlayerInfo";
import {useGame} from "../../../pages/GamePage";
import useAuth from "../../../hooks/useAuth";
import {useTranslation} from "react-i18next";

export default function Participant({player}: { player: PlayerInfo }) {
    const gameContext = useGame()
    const auth = useAuth()

    function playerIsHost(): boolean {
        return player.user?.id === auth.user?.id
    }

    function currentUserIsHost(): boolean {
        return gameContext.game.host.id === auth.user?.id
    }

    return <div>
        <Avatar sx={{width: 90, height: 90, m: 1, background: "Green"}}
                src={player.user?.avatarUrl ?? defaultProfilePicture}>
            {player.user?.id}
        </Avatar>
        <Typography sx={{textAlign: "center"}}>
            {player.user?.nickname}
            {currentUserIsHost() && !playerIsHost() && <KickPlayerButton player={player}/>}
        </Typography>
    </div>
}

function KickPlayerButton({player}: { player: PlayerInfo }) {
    const notification = useSnackbar()
    const {t} = useTranslation()
    const game = useGame()

    async function handleKick() {
        try {
            game.handleKick(player.user?.id ?? -1)
            notification.enqueueSnackbar(t("lobby.participants.successKick", {name: player.user?.nickname}), {variant: "success"})
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <CloseIcon onClick={handleKick} sx={{fontSize: 10, ml: 1, cursor: "pointer", color: "red"}}/>
}
