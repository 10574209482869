import TurnInfo from "../types/TurnInfo";
import ActionInfo from "../types/ActionInfo";
import {ActionType} from "../types/ActionType";
import {getTurnAtIndex} from "./TurnService";
import AddAction from "../types/AddAction";
import {findFieldByBuilderId, findFieldByXAndY} from "./GameService";
import GameInfo from "../types/GameInfo";
import {BuildActionType} from "../types/BuildActionType";
import {postObject} from "../functions/objectService";

export function getActionAtIndex(turn: TurnInfo | undefined, index: number) {
    return turn?.actions.sort((a, b) => a.id - b.id).reverse().at(index)
}

export function getLastActionOfType(actions: ActionInfo[], actionType: ActionType) {
    return actions.filter(action => action.type === actionType && action.godAbility === null).sort((a, b) => a.id - b.id).reverse().at(0)

}

export async function handleResetAction(turns: TurnInfo[], tokenId: string | undefined) {
    let currentTurn = getTurnAtIndex(0, turns)
    if (currentTurn !== undefined) {
        if (currentTurn.actions?.length > 1) {
            const addAction: AddAction = {
                type: ActionType.RESET,
                buildActionType: null,
                buildFieldId: null,
                fromFieldId: null,
                toFieldId: null,
                builderId: null,
                turnId: currentTurn.id,
                actionId: null
            }
            await postObject("/game/" + tokenId + "/add_action", addAction)
        }
    }
}

export async function handlePassGodAction(turns: TurnInfo[], tokenId: string | undefined) {
    let currentTurn = getTurnAtIndex(0, turns)
    if (currentTurn !== undefined) {
        if (currentTurn.actions?.length > 1) {
            const addAction: AddAction = {
                type: ActionType.PASS_GOD_ACTION,
                buildActionType: null,
                buildFieldId: null,
                fromFieldId: null,
                toFieldId: null,
                builderId: null,
                turnId: currentTurn.id,
                actionId: null
            }
            await postObject("/game/" + tokenId + "/add_action", addAction)
        }
    }
}

export async function handleMoveAction(game: GameInfo, x: number, y: number, builderId: number, turns: TurnInfo[], tokenId: string | undefined) {
    let toField = findFieldByXAndY(game.fields, x, y)
    let fromField = findFieldByBuilderId(builderId, game)
    let currentTurn = getTurnAtIndex(0, turns)
    let currentAction = getActionAtIndex(currentTurn, 0)
    if (fromField !== undefined && toField !== undefined && currentTurn !== undefined && currentAction !== undefined) {
        const addAction: AddAction = {
            type: ActionType.MOVE,
            buildActionType: null,
            buildFieldId: null,
            fromFieldId: fromField.id,
            toFieldId: toField.id,
            builderId: builderId,
            turnId: currentTurn.id,
            actionId: currentAction.id
        }
        await postObject("/game/" + tokenId + "/add_action", addAction)
    }
}

export async function handleAddBuilderAction(turns: TurnInfo[], game: GameInfo, x: number, y: number, tokenId: string | undefined) {
    let currentTurn = getTurnAtIndex(0, turns)
    let currentAction = getActionAtIndex(currentTurn, 0)
    let toField = findFieldByXAndY(game.fields, x, y)
    if (toField !== undefined && currentTurn !== undefined && currentAction !== undefined) {
        const addAction: AddAction = {
            type: ActionType.ADD_BUILDER,
            buildActionType: null,
            buildFieldId: null,
            fromFieldId: null,
            toFieldId: toField.id,
            builderId: null,
            turnId: currentTurn.id,
            actionId: currentAction.id
        }
        await postObject("/game/" + tokenId + "/add_action", addAction)
    }
}

export async function handleBuildAction(turns: TurnInfo[], game: GameInfo, x: number, y: number, tokenId: string | undefined) {
    let currentTurn = getTurnAtIndex(0, turns)
    let currentAction = getActionAtIndex(currentTurn, 0)
    let buildField = findFieldByXAndY(game.fields, x, y)
    if (buildField !== undefined && currentTurn !== undefined && currentAction !== undefined) {
        const addAction: AddAction = {
            type: ActionType.BUILD,
            buildActionType: BuildActionType.BUILD,
            buildFieldId: buildField.id,
            fromFieldId: null,
            toFieldId: null,
            builderId: null,
            turnId: currentTurn.id,
            actionId: currentAction.id
        }
        await postObject("/game/" + tokenId + "/add_action", addAction)
    }
}