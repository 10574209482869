import React, {createContext, useContext, useState} from "react";
import GameInfo, {getGameName} from "../types/GameInfo";
import useAuth from "./useAuth";
import {Badge, Menu, MenuItem, Tab, Tabs, Tooltip} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {useTranslation} from "react-i18next";
import useLiveGameData from "./useLiveGameData";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {LocalGroceryStoreOutlined} from "@mui/icons-material";
import useGameInvites from "./useGameInvites";
import useStats from "./stats/useStats";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const GameTabs = createContext<{
    games: GameInfo[]
}>({
    games: []
})

export default function useGameTabs() {
    return useContext(GameTabs)
}

export function GameTabsBridge(props: { children?: React.ReactNode }) {
    const auth = useAuth()
    if (auth.isAuthorized()) return <GameTabsProvider>{props.children ?? <></>}</GameTabsProvider>

    return <>{props.children}</>
}

export function GameTabsProvider(props: { children: React.ReactNode }) {
    const navigate = useNavigate()
    const data = useLiveGameData()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [gameMenuIsOpen, setGameMenuIsOpen] = useState(false)
    const location = useLocation()
    const {setOpen, Modal: LeaderboardModal} = useStats()
    const {t} = useTranslation()
    const {inviteCount} = useGameInvites()
    const {user} = useAuth()

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (data.games.length === 0) return;
        setAnchorEl(event.currentTarget);
        setGameMenuIsOpen(true)
    };

    function getTabValue(): "home" | "invites" | "game" | "profile" | "store" | "friends" {
        if (location.pathname.toLowerCase().includes("game")) return "game"
        if (location.pathname.toLowerCase().includes("invites")) return "invites"
        if (location.pathname.toLowerCase().includes("store")) return "store"
        if (location.pathname.toLowerCase().includes("friends")) return "friends"
        if (location.pathname.toLowerCase().includes("profile")) return "profile"
        return "home"
    }

    function currentTurnIsMeCount() {
        return data.gamesWithTurnIsMe().length
    }

    return <GameTabs.Provider value={{games: data.games}}>
        <div style={{position: "fixed", bottom: 0, width: "100vw"}} className={"flexElements centerElements"}>
            <Tabs
                sx={{background: "#debc8f", borderRadius: 1.5, boxShadow: "0 0 2px #000"}}
                value={getTabValue()}
                aria-label="GamePage tabs"
                variant={"scrollable"}
            >
                <Tab onClick={() => navigate("/")} value={"home"}
                     icon={<Tooltip title={t("navigation.home")}><HomeOutlinedIcon/></Tooltip>}/>
                <Tab onClick={() => navigate("/invites")} value={"invites"}
                     icon={<Tooltip title={t("navigation.invites")}>
                         {inviteCount > 0 ? <Badge badgeContent={`${inviteCount}`} color={"secondary"}>
                             <ForwardToInboxOutlinedIcon/>
                         </Badge> : <ForwardToInboxOutlinedIcon/>}
                     </Tooltip>}/>
                <Tab disabled={data.games.length === 0} onClick={handleClick} value={"game"}
                     icon={<Tooltip title={t("navigation.myGames")}>
                         {currentTurnIsMeCount() !== 0 ?
                             <Badge badgeContent={`${currentTurnIsMeCount()}`} color={"secondary"}>
                                 <SportsEsportsOutlinedIcon/>
                             </Badge> : <SportsEsportsOutlinedIcon/>}
                     </Tooltip>}/>
                <Tab onClick={() => navigate("/store")} value={"store"}
                     icon={<Tooltip title={t("navigation.store")}><LocalGroceryStoreOutlined/></Tooltip>}/>
                <Tab onClick={() => navigate("/friends")} value={"friends"}
                     icon={<Tooltip title={t("navigation.friends")}><PeopleOutlineIcon/></Tooltip>}/>
                <Tab onClick={() => setOpen(true)} value={"leaderboards"}
                     icon={<Tooltip title={t("navigation.leaderboardsAndStats")}><LeaderboardIcon/></Tooltip>}/>
                <Tab onClick={() => navigate("/profile")} value={"profile"}
                     icon={<Tooltip title={t("navigation.profile")}><AccountCircleOutlinedIcon/></Tooltip>}/>
            </Tabs>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={gameMenuIsOpen}
                onClose={() => setGameMenuIsOpen(false)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{top: -40}}
            >
                {data.games.map(game => <MenuItem sx={game.currentTurn?.user?.id === user?.id ? {color: "red"} : {}}
                                                  key={game.id} onClick={() => {
                    navigate(`/game/${game.linkToken}`)
                    setGameMenuIsOpen(false)
                }}>{getGameName(game)}</MenuItem>)}
            </Menu>
        </div>
        <div style={{zIndex: -1, position: "absolute", height: "100vh", width: "100vw", top: 0, left: 0}}>
            {props.children}
        </div>
        {LeaderboardModal}
    </GameTabs.Provider>
}
