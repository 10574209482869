import React, {useContext} from "react";
import GameContext from "../context/GameContext";
import GameContextProvider from "../context/GameContext.Provider";
import GameContextBridge from "../components/particles/game/GameContextBridge";

export function useGame() {
    return useContext(GameContext)
}

export default function GamePage() {
    return <GameContextProvider>
        <GameContextBridge/>
    </GameContextProvider>


}
