import React, {useContext} from "react";
import GameContext from "../../../context/GameContext";
import {IconButton} from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function InformationButton() {
    const gameContext = useContext(GameContext)

    return (<IconButton
        sx={{
            position: "absolute",
            left: 10,
            top: 25,
            zIndex: 2,
            cursor: 'pointer',
            padding: 0,
            height: "30px",
            width: "30px"
        }}
        onClick={() =>
            gameContext.setOpen(true)}><QuestionMarkIcon/></IconButton>)

}