export default interface User {
    id: number
    nickname: string
    avatarUrl: string
    email:string
    cardName:string
}

export interface CurrentUser {
    id: number
    firstname:string
    lastname:string
    email: string
    nick: string
    avatar: string
    rewardtokens:number
    cardName:string
    card:string
    exp: number
    iat: number
}
export enum UserRelationship {
    Friend="Friend",
    User="User",
    Requested="Requested",
    SentRequest="SentRequest",
    Nothing="Nothing"
}
export interface FriendUser {
    nickname:string
    avatarUrl:string
    cardName:string
    userRelationship:UserRelationship
}

export function defaultUser() : User {
    return {
        id: 0,
        nickname: "",
        avatarUrl: "",
        email:"",
        cardName:""
    }
}
export function defaultFriend() :FriendUser {
    return {
        nickname: "",
        avatarUrl: "",
        userRelationship: UserRelationship.Friend,
        cardName:""
    }
}