import Dome from "../../threejs/board/Dome";
import React from "react";
import FieldInfo from "../../../types/FieldInfo";
import Level3 from "../../threejs/board/Level3";
import Level2 from "../../threejs/board/Level2";
import Level1 from "../../threejs/board/Level1";


interface TowerProps {
    field: FieldInfo
}

export default function Tower({field}: TowerProps) {
    return (<>
        {field.towerSize === 4 && <Dome
            key={"dome-" + field.id} position={[field.x + 0.0045, 2.168, field.y + 0.004]}></Dome>}
        {field.towerSize >= 3 && <Level3
            key={"level3-" + field.id} position={[field.x + 0.0045, 1.6, field.y + 0.004]}></Level3>}
        {field.towerSize >= 2 && <Level2 key={"level2-" + field.id}
                                         position={[0.0052 + field.x, 0.87, 0.006 + field.y]}></Level2>}
        {field.towerSize >= 1 && <Level1 key={"level1-" + field.id} position={[field.x, 0, field.y]}></Level1>}
    </>)
}