import React from "react";
import BasicCard from "./BasicCard";
interface ReturnRightCardInterface  {
    name:String|undefined,
    nickname:string|undefined,
    avatar:string|undefined,
    buttonName:string,
    buttonFunction:() => Promise<void>|null,
    buttonFunction2:() => Promise<void>|null,
    userRelationShip:string,
    rewardTokens:number|undefined
}

export default function ReturnRightCard({name,nickname,avatar,buttonName,buttonFunction,buttonFunction2,userRelationShip,rewardTokens}:ReturnRightCardInterface) {
    const basicCard= {
        backgroundColor: "#debc8f",
        color:"#0F4E9D"
    };
    const flowerPowerCard= {
        backgroundColor: "#d33c7f",
        color:"#361f40"
    };
    const horrorCard ={
        backgroundColor: "#1a0f0e",
        color:"#ba182c"
    }
    const forestCard={
        backgroundColor: "#6f8b53",
        color:"#27321b"
    }
    const seaCard={
        backgroundColor: "#247ac9",
        color:"#1b273d"
    };
    const sofisticatedCard={
        backgroundColor: "#262e4e",
        color:"#58a3d6"
    }
    switch (name) {
        case 'FlowerPower':
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip}  avatar={avatar} nickname={nickname} background={flowerPowerCard.backgroundColor} color={flowerPowerCard.color}/>;
        case 'Forest':
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip} avatar={avatar} nickname={nickname} background={forestCard.backgroundColor} color={forestCard.color}/>;
        case 'Horror':
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip} avatar={avatar} nickname={nickname} background={horrorCard.backgroundColor} color={horrorCard.color}/>;
        case 'Sea':
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip} avatar={avatar} nickname={nickname} background={seaCard.backgroundColor} color={seaCard.color}/>;
        case 'Sofisticated':
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip} avatar={avatar} nickname={nickname} background={sofisticatedCard.backgroundColor} color={sofisticatedCard.color}/>;
        default:
            return <BasicCard rewardTokens={rewardTokens} buttonFunction2={buttonFunction2} buttonFunction={buttonFunction} buttonName={buttonName} userRelationShip={userRelationShip} avatar={avatar} nickname={nickname} background={basicCard.backgroundColor} color={basicCard.color}/>
    }

}