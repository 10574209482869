import {Avatar, Card, CardContent, Typography} from "@mui/material";
import React from "react";
import StatusComponent from "../User/StatusComponent";
import ReturnRightContentForCard from "./ReturnRightContentForCard";

interface cardInterface {
    nickname:string|undefined,
    avatar:string|undefined,
    rewardTokens:number|undefined,
    background:string,
    color:string,
    buttonName:string,
    buttonFunction:() => Promise<void>|null,
    buttonFunction2:() => Promise<void>|null,
    userRelationShip:string
}

export default function BasicCard({nickname,avatar,background,color,buttonName,buttonFunction,buttonFunction2,userRelationShip,rewardTokens}:cardInterface) {
    return(
    <Card  sx={{backgroundColor:{background},marginTop: 2,height:250}}>
        <Avatar sx={{
            height: 100,
            width: 100,
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block"
        }} src={avatar}/>
        <CardContent>
            <Typography  sx={{ textAlign: "center"}} variant="h5" component="div">
                <Typography variant={"h5"} fontFamily={"'Caesar Dressing', cursive"} color={color} fontWeight={"bold"}>{nickname}</Typography>
                <StatusComponent/>
            </Typography>
            <ReturnRightContentForCard rewardTokens={rewardTokens} buttonName={buttonName} buttonFunction={buttonFunction} buttonFunction2={buttonFunction2} userRelationShip={userRelationShip}/>
        </CardContent>
    </Card>
)
}
