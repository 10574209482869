import {useGetCollection} from "../../../hooks/useGetObject";
import User from "../../../types/User";
import React from "react";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ReturnRightCard from "../profileCard/ReturnRightCard";

let keyCounter = 0
export default function AllFriendsPage() {
    const {
        data: friends
    } = useGetCollection<User>("/friends/getAllFriends");
    const {t} = useTranslation();
    const friendsList = friends.map(user => {
        return <Grid key={keyCounter++} item xs={12} sm={6} md={4}>
            <ReturnRightCard rewardTokens={0} name={user.cardName} nickname={user.nickname} avatar={user.avatarUrl}
                             buttonName={""} buttonFunction={() => null} buttonFunction2={() => null}
                             userRelationShip={""}/>
        </Grid>
    });

    if (friends.length === 0) {
        return (
            <div>
                <Typography sx={{textAlign: "center"}} variant={"h3"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"#003D92"}
                            fontWeight={"bold"}>{t("friends.nofriends")}</Typography>
            </div>
        )
    } else {
        return (
            <Grid container sx={{padding: "5%", paddingBottom: "20%"}} spacing={5} columns={{xs: 4, sm: 8, md: 12}}>
                {friendsList}
            </Grid>
        )
    }
}
