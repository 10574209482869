import {Canvas} from "@react-three/fiber";
import React, {Suspense, useContext} from 'react'
import {OrbitControls} from "@react-three/drei";
import Island from "../../threejs/board/Island";
import CircleObject from "../../threejs/CircleObject";
import Tower from "./Tower";
import Builder from "./Builder";
import {
    CAMERA_POSITION_X,
    CAMERA_POSITION_Y,
    CAMERA_POSITION_Z,
    ISLAND_POSITION_X,
    ISLAND_POSITION_Y,
    ISLAND_POSITION_Z,
    LIGHT_1_X,
    LIGHT_1_Y,
    LIGHT_1_Z,
    LIGHT_2_X,
    LIGHT_2_Y,
    LIGHT_2_Z,
    LIGHT_INTENSITY,
    MAX_DISTANCE,
    MAX_POLAR_ANGLE,
    MIN_DISTANCE,
    MIN_POLAR_ANGLE,
    MIN_ZOOM
} from "../../../util/GameUtil";
import GameContext from "../../../context/GameContext";

export default function Board() {

    const gameContext = useContext(GameContext)

    return (
        <Suspense>
            <Canvas camera={{position: [CAMERA_POSITION_X, CAMERA_POSITION_Y, CAMERA_POSITION_Z]}} className={"canvas"}>
                <ambientLight/>
                <pointLight position={[LIGHT_1_X, LIGHT_1_Y, LIGHT_1_Z]} intensity={LIGHT_INTENSITY}/>
                <pointLight position={[LIGHT_2_X, LIGHT_2_Y, LIGHT_2_Z]} intensity={LIGHT_INTENSITY}/>
                <gridHelper args={[5, 5, "white", "white"]}/>
                <OrbitControls minZoom={MIN_ZOOM}
                               enablePan={true}
                               maxPolarAngle={MIN_POLAR_ANGLE}
                               minPolarAngle={MAX_POLAR_ANGLE}
                               minDistance={MIN_DISTANCE}
                               maxDistance={MAX_DISTANCE}
                />
                <Island position={[ISLAND_POSITION_X, ISLAND_POSITION_Y, ISLAND_POSITION_Z]}/>
                {gameContext.game.fields.map(field => {
                    return <Builder key={field.id} field={field} handlePawnClick={gameContext.handlePawnClick}/>
                })}
                {gameContext.game.fields.map(field => {
                    return <Tower key={field.id} field={field}/>
                })}
                {gameContext.moveCircles.map(circle => {
                    return <CircleObject key={circle.id} vector={[circle.x, circle.z, circle.y]}
                                         position={[circle.x, circle.z, circle.y]}
                                         onClickHandler={gameContext.handleCircleClick}></CircleObject>
                })}
                {gameContext.buildCircles.map(circle => {
                    return <CircleObject key={circle.id} vector={[circle.x, circle.z, circle.y]}
                                         position={[circle.x, circle.z, circle.y]}
                                         onClickHandler={gameContext.handleBuildCircleClick}></CircleObject>
                })}
                {gameContext.addBuilderCircles.map(circle => {
                    return <CircleObject key={circle.id} vector={[circle.x, circle.z, circle.y]}
                                         position={[circle.x, circle.z, circle.y]}
                                         onClickHandler={gameContext.handleAddBuilderCircleClick}></CircleObject>
                })}
            </Canvas>
        </Suspense>
    )
}
