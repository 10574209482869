import React from 'react';
import './App.scss';
import OldRouter from "./components/routers/Router";
import {SnackbarProvider} from "notistack";
import {Auth} from "./hooks/useAuth";
import theme from "./media/theme/theme";
import {ThemeProvider} from "@mui/material";
import './languages/i18n';
import {GameTabsBridge} from "./hooks/useGameTabs";
import {LiveGameDataBridge} from "./hooks/useLiveGameData";
import {BrowserRouter} from "react-router-dom";
import {PreferenceProvider} from "./hooks/usePreferences";

function App() {
    return <BrowserRouter>
        <PreferenceProvider>
            <SnackbarProvider maxSnack={5}>
                <ThemeProvider theme={theme}>
                    <Auth>
                        <LiveGameDataBridge>
                            <GameTabsBridge>
                                <OldRouter/>
                            </GameTabsBridge>
                        </LiveGameDataBridge>
                    </Auth>
                </ThemeProvider>
            </SnackbarProvider>
        </PreferenceProvider>
    </BrowserRouter>
}

export default App;
