import {defaultFriend, FriendUser} from "../../../types/User";
import {
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import useAuth from "../../../hooks/useAuth";
import {useSnackbar} from "notistack";
import {postObjectWithResponse, putObjectWithResponse} from "../../../functions/objectService";
import {Box} from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import ReturnRightCard from "../profileCard/ReturnRightCard";
import {useTranslation} from "react-i18next";

export default function AddFriendsPage() {
    const auth = useAuth();
    const notification = useSnackbar();
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState({
       nickname:""
    });
    const [user, setUser] = useState(defaultFriend);

    function handleChange(attribute: string, value: string) {
        setCredentials({...credentials, [attribute]: value})
    }

    async function onSubmit() {
        try {
            let response = await postObjectWithResponse<{ nickname: string}, FriendUser>("/friends/getSearchedFriend", credentials);
            console.log(response.data)
            let user: FriendUser ={
                nickname : response.data.nickname,
                avatarUrl: response.data.avatarUrl,
                userRelationship: response.data.userRelationship,
                cardName: response.data.cardName
            };
            setUser(user);
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed find user.", {variant: "error"})
            if ((`${e}`).includes("500")) notification.enqueueSnackbar("Can't find friend.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }
    async function sendFriendRequest() {
        try {
            const response = await putObjectWithResponse<{ nickname: string }, string>("/friends/sendFriendRequest", credentials);
            setUser(defaultFriend());
            notification.enqueueSnackbar(`Friend request send!`, {variant: "success"});
                auth.setToken(response.data);
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to send friend request.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }
    const AddCard= ( )=> {
        if (user.nickname === "") return <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"}
                                                    color={"#003D92"} fontWeight={"bold"}
                                                    sx={{textAlign: "center", marginTop:2}}>{t("friends.searchFriend")}</Typography>
        else return <Box component={"div"}
            sx={{maxWidth: 200, marginLeft: "auto", marginRight: "auto", display: "block", marginTop: 2,}}>
            <ReturnRightCard rewardTokens={0} buttonFunction2={()=>null} buttonFunction={sendFriendRequest} buttonName={"addFriend"} userRelationShip={user.userRelationship} name={user.cardName} nickname={user.nickname} avatar={user.avatarUrl}/>
        </Box>;
    }
    return (
        <div>
            <Box
                component="form"
                className={"flexElements centerElements verticalAlignCenter"}
            >
            <TextField variant="filled"  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange("nickname", e.target.value)}/>
                <IconButton  onClick={onSubmit} color="primary" aria-label="upload picture" component="label">
                    <SearchIcon style={{height:40,width:40}}/>
                </IconButton>
            </Box>
            {AddCard()}
        </div>
    )
}
