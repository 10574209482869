import {useGetCollection} from "../../../hooks/useGetObject";
import User from "../../../types/User";
import React from "react";
import {Grid, Typography} from "@mui/material";
import {putObjectWithResponse} from "../../../functions/objectService";
import useAuth from "../../../hooks/useAuth";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import ReturnRightCard from "../profileCard/ReturnRightCard";

let keyCounter = 0
export default function FriendRequestsPage() {
    const {t} = useTranslation();
    const auth = useAuth();
    const notification = useSnackbar();
    const {
        data: requests,
        refresh
    } = useGetCollection<User>("/friends/getFriendRequests");

    async function acceptRequest(nickname: string) {
        const acceptedRequest = {
            nickname: nickname
        };
        try {
            var response = await putObjectWithResponse<{ nickname: string }, string>("/friends/acceptFriendRequest", acceptedRequest);
            await refresh();
            auth.setToken(response.data)
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed add friend.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function deleteRequest(nickname: string) {
        const deletedRequest = {
            nickname: nickname
        };
        try {
            const response = await putObjectWithResponse<{ nickname: string }, string>("/friends/deleteFriendRequest", deletedRequest);
            await refresh();
            auth.setToken(response.data)
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed add friend.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    const friendsList = requests.map(user => {
        return <Grid key={keyCounter++} item xs={12} sm={6} md={4}>
            <ReturnRightCard rewardTokens={0} name={user.cardName} nickname={user.nickname} avatar={user.avatarUrl}
                             buttonName={"friendRequest"} buttonFunction={() => acceptRequest(user.nickname)}
                             buttonFunction2={() => deleteRequest(user.nickname)} userRelationShip={""}/>
        </Grid>
    });

    if (requests.length === 0) {
        return (
            <div>
                <Typography sx={{textAlign: "center"}} variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}>{t("friends.norequests")}</Typography>
            </div>
        )
    } else {
        return (
            <Grid container sx={{padding: "5%", paddingBottom: "20%"}} spacing={5} columns={{xs: 4, sm: 8, md: 12}}>
                {friendsList}
            </Grid>
        )
    }
}