import Friend from "./Friend";
import LobbySection from "./LobbySection";
import {useTranslation} from "react-i18next";
import {useGetCollection} from "../../../hooks/useGetObject";
import {Loading} from "../Loading";
import {useSnackbar} from "notistack";
import User from "../../../types/User";
import {useEffect} from "react";

export default function InviteFriends({gameToken}:{gameToken:number|string}) {
    const {data, loading, error, errorMsg} = useGetCollection<User>("/friends/get")
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if(error) {
            enqueueSnackbar("Failed to load friends: "+errorMsg)
        }
    }, [error, errorMsg, enqueueSnackbar])

    if(data.length === 0) return <></>

    if(loading) return <Loading />

    if(error) {
        return <></>
    }

    return <LobbySection title={t("lobby.friends.inviteBtn")}>
        {data.map(e => <Friend key={e.id} gameId={gameToken} user={e} />)}
    </LobbySection>
}
