// noinspection JSStringConcatenationToES6Template

import {ProfileParams} from "./SideInfo";
import "./PlayerDetail.scss"
import {Avatar, Tooltip, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {BOT_NAME} from "../../../util/GameUtil";

export default function PlayerDetail(playerDetail: ProfileParams) {
    const {t} = useTranslation()

    function getColorHex(color: string) {
        switch (color) {
            case 'Yellow':
                return '#EEC94D'
            case 'Red':
                return '#F1733A'
            case 'Green':
                return '#009460'
            case 'Pink':
                return '#D597CC'
        }
    }

    return (
        <div className="player-detail"
             style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center",
                 alignItems: "center",
             }}>
            <Avatar style={{
                border: playerDetail.hasTurn ? "3px solid " + getColorHex(playerDetail.color) : "3px solid transparent",
                width: 50,
                height: 50
            }}
                    alt={playerDetail.name} src={playerDetail.url === "" ? "/images/bot/bot.png" : playerDetail.url}/>
            <Typography>{playerDetail.name === "" ? BOT_NAME : playerDetail.name}</Typography>
            {(playerDetail.god !== null && playerDetail.god.name !== "Human") && <><Typography
                fontFamily={"'Caesar Dressing', cursive"}
                fontWeight={"bold"}>{playerDetail.god.name}</Typography>
                <Tooltip title={t("game.god-actions." + playerDetail.god.name.toLowerCase())}>
                    <img style={{height: 50, width: "auto"}}
                         src={"/images/godactions/" + playerDetail.god.name.toLowerCase() + ".png"} alt={"GodActions"}/>
                </Tooltip></>}

        </div>
    )
}
