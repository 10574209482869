import {Card, Divider} from "@mui/material";
import ActionDetail from "./ActionDetail";
import TurnInfo from "../../../types/TurnInfo";
import "./Actions.scss"
import {useTranslation} from "react-i18next";
import ActionInfo from "../../../types/ActionInfo";

interface ActionsProps {
    turns: TurnInfo[]
}

export default function Actions({turns}: ActionsProps) {
    const {t} = useTranslation()

    function gameHasNoActions() {
        if (turns.length > 1) return false
        if (turns.length === 0) return true
        if (turns.length === 1 && turns.at(0)?.actions.length === 1) {
            return isEmptyAction(turns.at(0)?.actions.at(0));
        }

    }

    function isEmptyAction(action: ActionInfo | undefined) {
        return action?.buildActionType === null && action.toField === null && action.fromField === null;
    }

    function getCurrentTurn() {
        return turns.sort((a, b) => a.id - b.id)
    }

    function getSortedListOfTurnsWithActions() {
        return getCurrentTurn().map(turn => (turn.actions.filter(action => !isEmptyAction(action)).sort((a, b) => a.id - b.id).map(action => (
            <ActionDetail key={action.id} action={action} player={turn.player}/>))))
    }

    return (<>
        <Card sx={{
            minWidth: 250,
            minHeight: 250,
            maxHeight: 250,
            background: "linear-gradient(180deg, #eacda3, #d6ae7b)",
            overflowY: "auto",
            marginTop: 3,
            width: "100%",
        }} className={"cardActions"}>
            <div style={{
                width: "100%",
                color: "grey",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 10
            }}>{t("game.actions.title")}
            </div>
            <Divider></Divider>
            {
                gameHasNoActions() ?
                    <div style={{display: "flex", height: "100%"}}>
                        <div style={{
                            fontStyle: "italic",
                            width: "100%",
                            height: "100%",
                            textAlign: "center"
                        }}>{t("game.actions.no-actions")}
                        </div>
                    </div> : getSortedListOfTurnsWithActions()
            }
        </Card>
    </>)
}