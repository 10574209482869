import React, {createContext, useCallback, useContext, useEffect} from "react";
import {useGetCollection} from "./useGetObject";
import GameInfo from "../types/GameInfo";
import {useSnackbar} from "notistack";
import useInterval from "./useInterval";
import {Loading} from "../components/particles/Loading";
import useAuth from "./useAuth";
import {useLocation} from "react-router-dom";
import usePreferences from "./usePreferences";

const LiveGameData = createContext<{
    games: GameInfo[],
    gamesWithTurnIsMe: () => GameInfo[]
}>({
    games: [],
    gamesWithTurnIsMe: () => {return []}
})

export default function useLiveGameData() {
    return useContext(LiveGameData)
}

export function LiveGameDataBridge(props: { children: React.ReactNode}) {
    const {isAuthorized} = useAuth()

    if(!isAuthorized()) return <>{props.children}</>
    return <LiveGameDataProvider>
        {props.children}
    </LiveGameDataProvider>
}

const bell = new Audio("/mp3/bell.mp3")
let loads = 0
let lastTurnsOfMe : GameInfo[] = []
function LiveGameDataProvider(props: { children: React.ReactNode}) {
    const {enqueueSnackbar} = useSnackbar()
    const {user} = useAuth()
    const {pathname} = useLocation()
    const {SoundEffects, NotificationsOnTurnStart} = usePreferences()
    const {data: games, loading, error, errorMsg, refresh : refreshGames, hasLoaded} = useGetCollection<GameInfo>("/game/user")
    useInterval(refreshGames, useBestDelay(error))
    const gamesWithTurnIsMe = useCallback(() => {
        return games.filter(e => e.currentTurn?.user?.id === user?.id)
    }, [games, user?.id])

    useEffect(() => {
        if(error) enqueueSnackbar(errorMsg, {variant: "error"})
    }, [error, errorMsg, enqueueSnackbar])

    useEffect(() => {
        const arraySplitted = pathname.split('/')
        const possibleToken = arraySplitted[arraySplitted.length-1]
        const myTurnStartedFor = gamesWithTurnIsMe().filter(game => lastTurnsOfMe.findIndex(game2 => game2.id === game.id) < 0)
        if(myTurnStartedFor.filter(e => e.linkToken !== possibleToken).length > 0 && loads > 2) {
            if(NotificationsOnTurnStart.value) enqueueSnackbar("Your turn has started somewhere!", {variant: "success"})
            bell.volume = 0.5
            if(SoundEffects.value) bell.play()
        } else loads++
        lastTurnsOfMe = gamesWithTurnIsMe()
    }, [enqueueSnackbar, NotificationsOnTurnStart.value, SoundEffects.value, pathname, gamesWithTurnIsMe])

    if(loading && !hasLoaded) return <Loading />

    return <LiveGameData.Provider value={{
        games: games,
        gamesWithTurnIsMe: gamesWithTurnIsMe
    }}>
        {props.children}
    </LiveGameData.Provider>
}

function useBestDelay(errorOccured:boolean) {
    const location = useLocation()

    let standardDelay = 3
    if(location.pathname.includes("/game")) standardDelay += 7
    else if(errorOccured) standardDelay += 2
    return standardDelay
}