import {Vector3} from '@react-three/fiber'
import {useGLTF} from '@react-three/drei'
import {GLTF} from "three-stdlib";


interface CircleProps {
    vector: Vector3,
    position: number[],
    onClickHandler: (x: number, y: number) => void
}

type GLTFResult = GLTF & {
    nodes: {
        Cylinder: THREE.Mesh
    }
    materials: {
        ['Material.001']: THREE.MeshStandardMaterial
    }
}

export default function CircleObject({vector, position, onClickHandler}: CircleProps) {
    const {nodes, materials} = useGLTF('/circle.glb') as unknown as GLTFResult
    return (<group dispose={null} onClick={(e) => {
        e.stopPropagation()
        onClickHandler(position[0], position[2])

    }}>
        <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.001']} scale={[0.4, 0.05, 0.4]}
              position={vector}/>
    </group>)
}

useGLTF.preload('/circle.glb')