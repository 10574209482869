import {Button, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/system";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {postObjectWithResponseWithHeader} from "../functions/objectService";
import Background from "../components/particles/Background";
import GameTitle from "../components/particles/game/GameTitle";

interface IRegister {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    avatarUrl: string;
}

const schema = yup.object({
    firstname: yup.string().required().max(20).min(2),
    lastname: yup.string().required().max(20).min(2),
    username: yup.string().required().max(10).min(2),
    email: yup.string().required().min(2).matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,"Is not a valid email"),
    password: yup.string().required().max(20).min(8),
});
export default function Registration() {
    const {t} = useTranslation();
    const notification = useSnackbar();
    const [credentials, setCredentials] = useState({password: "", repeatPassword: ""});
    const {register, formState: {errors}, handleSubmit} = useForm<IRegister>({
        resolver: yupResolver(schema)
    });
    const onSubmit: SubmitHandler<IRegister> = data => SubmitData(data);
    const navigate = useNavigate();

    function handleChange(attribute: string, value: string) {
        setCredentials({...credentials, [attribute]: value})
    }

    function validCredentials() {
        return credentials.password === credentials.repeatPassword;
    }

    async function SubmitData(data: IRegister) {
        try {
            await postObjectWithResponseWithHeader("/auth/register", data, {});
            notification.enqueueSnackbar(`Account created`, {variant: "success"});
            navigate("/login")
        } catch (e: any) {
            if ((`${e}`).includes("401")) notification.enqueueSnackbar("Failed to create an account.", {variant: "error"})
            if ((`${e}`).includes("400")) notification.enqueueSnackbar("Email/username already exists.", {variant: "error"})
            else notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }


    return <div className={"flexElements centerElements verticalAlignCenter"}>
        <Background>
            <Box
                component="form"
                noValidate
                sx={{maxWidth: 800, overflowY: "auto"}}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <GameTitle/>
                <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("registration.register")}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("registration.firstname")}
                    variant="filled"
                    {...register("firstname")}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.firstname?.message}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("registration.lastname")}
                    variant="filled"
                    {...register("lastname", {required: true, maxLength: 20, minLength: 2})}
                    aria-invalid={errors.lastname ? "true" : "false"}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.lastname?.message}</Typography>
                <TextField
                    fullWidth
                    margin={"dense"}
                    className="textfieldLogin"
                    label={t("registration.email")}
                    variant="filled"
                    {...register("email", {required: true})}
                    aria-invalid={errors.lastname ? "true" : "false"}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.email?.message}</Typography>
                <TextField
                    fullWidth
                    className="textfieldLogin"
                    margin={"dense"}
                    label={t("registration.username")}
                    variant="filled"
                    {...register("username", {required: true, maxLength: 10, minLength: 2})}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.username?.message}</Typography>
                <TextField
                    fullWidth
                    className="textfieldLogin"
                    margin={"dense"}
                    type={"password"}
                    label={t("registration.password")}
                    variant="filled"
                    {...register("password")}
                    value={credentials.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                />
                <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                            color={"red"}>{errors.password?.message}</Typography>
                <TextField
                    fullWidth
                    className="textfieldLogin"
                    margin={"dense"}
                    type={"password"}
                    label={t("registration.repeatpassword")}
                    variant="filled"
                    value={credentials.repeatPassword}
                    onChange={(e) => handleChange("repeatPassword", e.target.value)}
                />
                <Button disabled={!validCredentials()} sx={{m: 1}} type="submit"
                        variant="contained">{t("registration.register")}</Button>
                <Button sx={{m: 1}} onClick={() => navigate("/login")}
                        variant="contained">{t("registration.exists")}</Button>
            </Box>
        </Background>
    </div>
}
