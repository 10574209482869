import {useJwt} from "react-jwt";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getToken, putToken} from "../functions/tokenService";
import {CurrentUser} from "../types/User";
import useInterval from "./useInterval";
import {setAuthHeader} from "../functions/objectService";
import ErrorPage from "../pages/errors/ErrorPage";

function DefaultAuthorized(props: { children: JSX.Element, displayErrorMsg?: boolean }) {
    return <CheckAccessAuthorized token={""}
                                  children={props.children} displayErrorMsg={props.displayErrorMsg}/>
}

function DefaultNotAuthorized(props: { children: JSX.Element }) {
    return <CheckAccessNotAuthorized token={""}
                                     children={props.children}/>
}

interface AuthInterface {
    Authorized: (props: { children: JSX.Element, displayErrorMsg?: boolean }) => JSX.Element
    NotAuthorized: (props: { children: JSX.Element }) => JSX.Element
    Token: string | undefined
    setToken: (e: string) => void
    user: CurrentUser | null
    isAuthorized: () => boolean
    verifyToken: () => void
}


export let AuthContext = createContext<AuthInterface>({
    Authorized: DefaultAuthorized,
    NotAuthorized: DefaultNotAuthorized,
    Token: "",
    setToken: () => {
    },
    user: null,
    isAuthorized: defaultIsAuthorized,
    verifyToken: () => {
    }
})

export default function useAuth() {
    return useContext(AuthContext)
}

export function Auth({children}: { children: React.ReactNode }) {
    const {enqueueSnackbar} = useSnackbar()
    const [token, setToken] = useState<string | undefined>(getToken())
    const {decodedToken, isExpired} = useJwt<CurrentUser>(token ?? "")
    const checkToken = useCallback(() => {
        putToken(token)
        //console.log(`Checking token validity, has expired: ${isExpired}`)
        if (isExpired || decodedToken === null || token === "") {
            return false
        }
        //manual expired
        if (decodedToken != null && decodedToken.exp < (new Date().getTime() / 1000)) {
            //console.log(`Should be expired by now.`)
            setToken(undefined)
            setAuthHeader()
            enqueueSnackbar('Your session has expired.', {variant: "warning"})
            return false
        }
        return true
    }, [decodedToken, enqueueSnackbar, isExpired, token])
    useInterval(checkToken, 10)

    useEffect(() => {
        checkToken()
    }, [token, isExpired, checkToken])

    function isAuthorized(): boolean {
        if (token == null || token === "") return false
        if (decodedToken == null || isExpired) return false //token has expired or is invalid
        return true
    }

    function Authorized(props: { children: JSX.Element, displayErrorMsg?: boolean }) {
        return <CheckAccessAuthorized token={token ?? ""}
                                      children={props.children} displayErrorMsg={props.displayErrorMsg}/>
    }

    function NotAuthorized(props: { children: JSX.Element }) {
        if (decodedToken == null || token === "" || isExpired) return props.children //token is invalid or has expired, so is not logged in
        return <CheckAccessNotAuthorized token={token ?? ""}
                                         children={props.children}/>
    }

    return <AuthContext.Provider value={{
        Authorized: Authorized,
        NotAuthorized: NotAuthorized,
        Token: token,
        setToken: (e: string) => {
            putToken(e)
            setToken(e)
        },
        user: decodedToken,
        isAuthorized: isAuthorized,
        verifyToken: checkToken
    }}>
        {children}
    </AuthContext.Provider>
}

function defaultIsAuthorized(): boolean {
    return false
}

const Error400 = <ErrorPage code={400} message={"Access denied"}/>

function CheckAccessAuthorized({
                                   token,
                                   children,
                                   displayErrorMsg
                               }: { token: string, children: JSX.Element, displayErrorMsg?: boolean }) {
    const {decodedToken, isExpired} = useJwt<CurrentUser>(token)
    if (token == null || token === "") return displayErrorMsg ? <>{Error400}</> : <></>
    if (decodedToken == null || isExpired) return displayErrorMsg ? <>{Error400}</> : <></> //token has expired or is invalid
    return children
}


function CheckAccessNotAuthorized({
                                      token,
                                      children
                                  }: { token: string, children: JSX.Element }) {
    const {decodedToken, isExpired} = useJwt<CurrentUser>(token)
    if (token == null || token === "") return children
    if (decodedToken == null || isExpired) return children //token has expired or is invalid
    return <></>
}
