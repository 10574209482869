export const DOME_SCALE = 0.035
export const DOME_Y = 3.84
export const ISLAND_SCALE = 5
export const ISLAND_SCALE_ISLAND = 0.01
export const LEVEL_SCALE = 0.035
export const BUILDER_SCALE = 0.030
export const BUILDER_POSITION_X = 0
export const BUILDER_POSITION_Y = 11.31
export const BUILDER_POSITION_Z = 0
export const BUILDER_ROTATION_X = 0
export const BUILDER_ROTATION_Y = Math.PI / 2
export const BUILDER_ROTATION_Z = 0
export const CAMERA_POSITION_X = 5
export const CAMERA_POSITION_Y = 2
export const CAMERA_POSITION_Z = 2
export const LIGHT_1_X = 0
export const LIGHT_1_Y = 5
export const LIGHT_1_Z = 5
export const LIGHT_2_X = 0
export const LIGHT_2_Y = 5
export const LIGHT_2_Z = 0
export const LIGHT_INTENSITY = 0.4
export const ISLAND_POSITION_X = 0
export const ISLAND_POSITION_Y = -0.022
export const ISLAND_POSITION_Z = 0
export const MIN_POLAR_ANGLE = 0.9
export const MAX_POLAR_ANGLE = 0.9
export const MIN_DISTANCE = 7
export const MAX_DISTANCE = 10
export const MIN_ZOOM = 0.1
export const LEVEL_3_HEIGHT = 2.168
export const LEVEL_2_HEIGHT = 1.6
export const LEVEL_1_HEIGHT = 0.87
export const DEFAULT_HEIGHT = 0
export const BOT_NAME = "Bot"
