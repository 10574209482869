import {createContext} from "react";
import GameInfo, {defaultGameInfo} from "../types/GameInfo";
import Circle, {CircleWithoutBuilder} from "../types/Circle";
import TurnInfo from "../types/TurnInfo";

const GameContext = createContext<{
    game: GameInfo,
    moveCircles: Circle[],
    buildCircles: Circle[],
    addBuilderCircles: CircleWithoutBuilder[],
    builderId: number | undefined,
    turns: TurnInfo[],
    handlePawnClick: (builderIdFromParam: number | undefined) => void,
    handleCircleClick: (x: number, y: number) => void,
    handleBuildCircleClick: (x: number, y: number) => void,
    handleAddBuilderCircleClick: (x: number, y: number) => void,
    resetCirclesAndButtons: () => void;
    handleLeaveGame: () => void,
    handleStartGame: () => void,
    hasGameStarted: (game: GameInfo) => boolean,
    hasGameBeenWon: (game: GameInfo) => boolean,
    loadingTurns: boolean,
    loading: boolean,
    tokenId: string | undefined,
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    handleResetButtonClick: () => void,
    handlePassGodButtonClick: () => void;
    resetButtonActive: boolean,
    passGodActionButtonActive: boolean,
    inviteUser: (userId: number) => void,
    handleKick: (id: number) => void
}>({
    game: defaultGameInfo,
    moveCircles: [],
    buildCircles: [],
    addBuilderCircles: [],
    resetButtonActive: false,
    passGodActionButtonActive: false,
    loadingTurns: true,
    loading: true,
    tokenId: "",
    builderId: undefined,
    isOpen: false,
    setOpen: () => {
    },
    turns: [],
    handleResetButtonClick: () => {
    },
    resetCirclesAndButtons: () => {
    },
    handlePassGodButtonClick: () => {
    },
    handlePawnClick: () => {
    },
    handleCircleClick: () => {
    },
    handleBuildCircleClick: () => {
    },
    handleLeaveGame: () => {
    },
    handleStartGame: () => {
    },
    inviteUser: () => {
    },
    handleAddBuilderCircleClick: () => {

    },
    hasGameBeenWon: () => {
        return false
    },
    hasGameStarted: () => {
        return false
    },
    handleKick: () => {
    }
})

export default GameContext;