import Background from "../components/particles/Background";
import GameTitle from "../components/particles/game/GameTitle";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {postObjectWithResponse} from "../functions/objectService";
import {Button, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";


export default function EnableAccountPage() {
    const {token} = useParams<{ token: string }>();
    const {t} = useTranslation()
    const notification = useSnackbar();
    const navigate = useNavigate();

    async function onSubmit() {
        try {
            await postObjectWithResponse<{ token: string | undefined }, string>("/auth/enableUser", {token: token});
            navigate("/Login");
            notification.enqueueSnackbar(`User succesfully enabled`, {variant: "success"})
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Background>
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Box
                component="div"
                sx={{maxWidth: 700}}
            >
                <GameTitle/>
                <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("enableaccount.enableaccounttitle")}</Typography>
                <Button onClick={onSubmit} variant={"contained"}>{t("enableaccount.enableaccount")}</Button>
            </Box>
        </div>
    </Background>
}
