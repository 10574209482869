import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import {FriendsLeaderboard, RecentWorldLeaderboard, WorldLeaderboard} from "./Leaderboards";
import {useTranslation} from "react-i18next";
import MyGames from "./MyGames";

enum leaderboardTypes {
    'world',
    'friends',
    'recent'
}

export default function useStats() {
    const [open, setOpen] = useState(false)

    return {
        setOpen: setOpen,
        open: open,
        Modal: <LeaderboardModal open={open} handleClose={() => setOpen(false)}/>
    }
}

function LeaderboardModal({open, handleClose}: { open: boolean, handleClose: () => void }) {
    const {t} = useTranslation()
    const [tabV, setTab] = useState<leaderboardTypes>(leaderboardTypes.world)
    const [page, setPage] = useState<"leaderboards" | "myGames">("leaderboards")

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("leaderboard.leaderboardAndGames")}</DialogTitle>
        <DialogContent>
            <Tabs sx={{width: "100%"}} centered value={page} onChange={(e, v) => setPage(v)}>
                <Tab label={t("leaderboard.leaderboards")} value={"leaderboards"}/>
                <Tab label={t("leaderboard.myGames")} value={"myGames"}/>
            </Tabs>
            {page === "leaderboards"
                ? <>
                    <LeaderboardTabs tabV={tabV} setTab={setTab}/>
                    {tabV === leaderboardTypes.world && <WorldLeaderboard/>}
                    {tabV === leaderboardTypes.friends && <FriendsLeaderboard/>}
                    {tabV === leaderboardTypes.recent && <RecentWorldLeaderboard/>}
                </>
                : <MyGames />}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t("leaderboard.close")}</Button>
        </DialogActions>
    </Dialog>
}

function LeaderboardTabs({tabV, setTab}: { tabV: leaderboardTypes, setTab: (e: leaderboardTypes) => void }) {
    const {t} = useTranslation()

    return <Tabs color={"secondary"} value={tabV} onChange={(e, v) => setTab(v)}>
        <Tab label={t("leaderboard.world")} value={leaderboardTypes.world}/>
        <Tab label={t("leaderboard.friends")} value={leaderboardTypes.friends}/>
        <Tab label={t("leaderboard.recent")} value={leaderboardTypes.recent}/>
    </Tabs>
}
