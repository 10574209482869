import {Button, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {postObjectWithResponseWithHeader} from "../functions/objectService";
import GameTitle from "../components/particles/game/GameTitle";

interface IResetPassword {
    password: string;
}

const schema = yup.object({
    password: yup.string().required().max(20).min(8),
});

export default function ResetPasswordPage() {
    const {passwordToken} = useParams<{ passwordToken: string }>();
    const [credentials, setCredentials] = useState({password: "", repeatPassword: ""});
    const notification = useSnackbar();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {register, formState: {errors}, handleSubmit} = useForm<IResetPassword>({
        resolver: yupResolver(schema)
    });
    const onSubmit: SubmitHandler<IResetPassword> = data => SubmitData(data);

    function handleChange(attribute: string, value: string) {
        setCredentials({...credentials, [attribute]: value})
    }

    function validCredentials() {
        return credentials.password === credentials.repeatPassword;
    }

    async function SubmitData(data: IResetPassword) {
        try {
            await postObjectWithResponseWithHeader<{ password: string, passwordToken: string | undefined }, string>("/reset/ResetPassword", {
                password: data.password,
                passwordToken: passwordToken
            }, {})
            navigate("/Login")
            notification.enqueueSnackbar(`Password succesfully changed`, {variant: "success"})

            navigate("/")
        } catch (e: any) {
            notification.enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return (
        <div className={"flexElements centerElements verticalAlignCenter"}>
            <Box
                component="form"
                noValidate
                sx={{maxWidth: 400}}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <GameTitle/>
                <Typography variant={"h3"} fontFamily={"'Caesar Dressing', cursive"} color={"#003D92"}
                            fontWeight={"bold"}
                            sx={{textAlign: "center"}}>{t("resetpassword.resetpasswordtitle")}</Typography>
                <div>
                    <TextField
                        fullWidth
                        type={"password"}
                        margin={"dense"}
                        label={t("resetpassword.password")}
                        variant="filled"
                        value={credentials.password}
                        {...register("password")}
                        onChange={(e) => handleChange("password", e.target.value)}
                    />
                    <Typography variant={"body2"} fontFamily={"'Caesar Dressing', cursive"}
                                color={"red"}>{errors.password?.message}</Typography>
                    <TextField
                        fullWidth
                        type={"password"}
                        margin={"dense"}
                        label={t("resetpassword.repeatpassword")}
                        variant="filled"
                        value={credentials.repeatPassword}
                        onChange={(e) => handleChange("repeatPassword", e.target.value)}/>
                    <Button type="submit" disabled={!validCredentials()}
                            variant={"contained"}>{t("resetpassword.changepassword")}</Button>
                </div>
            </Box>
        </div>
    )
}